import React, { useState, useEffect, useMemo, useCallback } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  TextField,
  makeStyles,
  Button,
  ButtonProps as MuiButtonProps,
  MenuItem,
  Switch,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader,
  Tooltip,
} from "@material-ui/core";
import { Input } from "@mui/material";
import { Close, Delete } from "@material-ui/icons";
import Map from "./Map";
import SendTextMessageDialog from "./SendTextMessageDialog";
import {
  CHANNEL,
  ORDER_DATA_LABEL,
  PAYMENTS,
  STATUS,
  TRIP,
  VEHICLE,
} from "../../lib/constants/constants";
import { formatDate, formatCompleteTime } from "../../lib/datetime";
import { vehicleByRiderFilter } from "../../lib/orders/charge";
import { checkField } from "../../lib/validate";
import { zipDeliveryReceipts } from "../../lib/api/aws";
import { orderStatus, kakaoOrderStatus } from "./OrderDetailStatus";
import { AdminUserAuthsType } from "../../containers/users/types";
import { usersUserNull } from "../../containers/orders/types";
import {
  AlgoOrders,
  DEFAULT_CORPORATION,
  DEFAULT_EXCHARGES,
  OrderByPkType,
  ChangeableType,
  KakaoType,
  KakaoReports,
  ReceiptImgType,
} from "../../containers/order/types";
import {
  maskingPhoneNumber,
  maskingAddress,
} from "../../containers/order/utils";
import {
  FlexBox,
  MarginTopSelectDiv,
  FlexCenterSpaceBetween,
  FlexColumnCenter,
  RowDivider,
  ColumnDivider,
  FlexBoxWith100,
} from "../common/Base";
import {
  HalfTextField,
  OneThirdTextField,
  QuarterTextField,
  OneFifthTextField,
  WidthTextField,
} from "../common/StyledTextField";
import { WhiteSpacePaper } from "../common/MaterialBase";
import Detail from "../common/Detail";

interface OrderDetailProps {
  row: OrderByPkType;
  changeable: ChangeableType;
  onChange: (
    e: React.ChangeEvent<{
      name: string;
      value: string | number | boolean;
      checked?: boolean;
    }>
  ) => void;
  onSubmit: (event: React.FormEvent) => void;
  handleReOrder: () => void;
  orderUser: string;
  setOrderUser: React.Dispatch<React.SetStateAction<string>>;
  submitOrderUser: () => void;
  closeOrderUser: () => void;
  deleteOrderUser: () => void;
  deleteRelatedUser: () => void;
  inputOpen: boolean;
  setInputOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleOrderSync: (isd: string | null) => void;
  handleExternalNumber: (id: string, external: string | null) => void;
  kakaoLog: boolean;
  handleKakaoLog: () => void;
  kakao: KakaoType;
  kakaoOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  kakaoSubmit: (event: React.FormEvent) => void;
  handleCall24Api: () => void;
  handleCancelCard: () => void;
  repaymentCard: () => void;
  handleOrderSwap: () => void;
  isTextMessageDialogOpen: boolean;
  setTextMessageDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseDialog: () => void;
  handleChangeDialog: (text: string) => void;
  textMessage: string;
  sendTextMessage: () => void;
  receiptImages: ReceiptImgType[];
  receiptImageUrl: string;
  handleSetReceiptImageUrl: React.Dispatch<React.SetStateAction<string>>;
  isReceiptDialogOpen: boolean;
  handleToggleIsReceiptDialogOpen: () => void;
  handleSendReceiptKakao: () => void;
  receiptFileMaxCnt: number;
  isReceiptUploading: boolean;
  handleUploadReceiptFile: (e: any) => void;
  deletingReceiptFilename: string;
  handleDeleteReceiptFile: any;
  openUseStateModal: () => void;
  useStatementDisabled: boolean;
}

/**
 * @description 인수증 추가/첨부
 * @material-ui Button 컴포넌트의 component 속성에 문자열이 필요한 경우(component="span")
 * Button 컴포넌트의 component prop의 타입을 확장하여 문자열을 허용해준다.
 */
type ButtonProps = MuiButtonProps & {
  component?: string | React.ElementType;
};

function OrderDetail({
  row,
  changeable,
  onChange,
  onSubmit,
  handleReOrder,
  orderUser,
  inputOpen,
  setInputOpen,
  setOrderUser,
  submitOrderUser,
  closeOrderUser,
  deleteOrderUser,
  deleteRelatedUser,
  handleOrderSync,
  handleExternalNumber,
  kakaoLog,
  handleKakaoLog,
  kakao,
  kakaoOnChange,
  kakaoSubmit,
  handleCall24Api,
  handleCancelCard,
  repaymentCard,
  handleOrderSwap,
  isTextMessageDialogOpen,
  setTextMessageDialogOpen,
  handleCloseDialog,
  handleChangeDialog,
  textMessage,
  sendTextMessage,
  receiptImages = [],
  receiptImageUrl,
  handleSetReceiptImageUrl,
  isReceiptDialogOpen,
  handleToggleIsReceiptDialogOpen,
  handleSendReceiptKakao,
  receiptFileMaxCnt,
  isReceiptUploading,
  handleUploadReceiptFile,
  deletingReceiptFilename,
  handleDeleteReceiptFile,
  openUseStateModal,
  useStatementDisabled,
}: OrderDetailProps) {
  const history = useHistory();
  const classes = useStyles();
  const adminUserAuth = useSelector(
    ({ userAuth }: RootStateOrAny) =>
      userAuth.userAuth?.data?.users_adminuserauth
  );
  const [corporation, setCorporation] = useState(DEFAULT_CORPORATION);
  const [extraCharge, setExtraCharge] = useState<AlgoOrders>(DEFAULT_EXCHARGES);

  const {
    algoquick_kakaoreports,
    algoquick_orders,
    aq_pickup_request_time,
    client_department,
    corporate_account_memo,
    costcenter,
    costcenter_change_memo,
    external_isd_order_number,
    id,
    isd_pickup_request_time,
    rider_name,
    rider_phone_number,
    usersUserByRelatedUserId,
    users_user,
    vehicle_option,
    vehicle_type,
  } = row;

  /**
   * @description 메뉴명 권한
   * @returns { string{} | undefined }
   */
  const ORDERS = useMemo(
    () =>
      adminUserAuth?.find(
        ({ menu_id }: AdminUserAuthsType) => menu_id === "ORDERS"
      ),
    [adminUserAuth]
  );

  useEffect(() => {
    /**
     * @property { object } users_user - 일반회원, 법인회원
     * @property { object } usersUserByRelatedUserId - 비회원, 단체주문, 전화접수 (DB에 저장된 연락처)
     * @property { null } - 비회원, 단체주문, 전화접수 (DB에 없는 연락처)
     */
    const { corporations_corporation: corpData } =
      users_user || usersUserByRelatedUserId || usersUserNull;
    setCorporation({
      name: corpData?.name || "",
      billing_department_enable: corpData?.billing_department_enable || false,
      costcenter_change_memo_enable:
        corpData?.costcenter_change_memo_enable || false,
    });

    // 추가 요금
    if (algoquick_orders) setExtraCharge(algoquick_orders[0]);
  }, [row]);

  const [vehicleType, vehicleOption] = useMemo(
    () =>
      [VEHICLE[vehicle_type], vehicle_option]
        .map(checkField)
        .map((v) => vehicleByRiderFilter(v, rider_name)),
    [rider_name, vehicle_type, vehicle_option]
  );

  // 예약시간
  const pickupRequestTime = useMemo(() => {
    let requestTime = aq_pickup_request_time || isd_pickup_request_time;
    return formatDate(checkField(requestTime));
  }, [aq_pickup_request_time, isd_pickup_request_time]);

  const receiptUploadEnabled = useMemo(
    () => receiptImages.length < receiptFileMaxCnt,
    [receiptImages, receiptFileMaxCnt]
  );
  const receiptUploadDisabled = useMemo(
    () => isReceiptUploading || !receiptUploadEnabled,
    [isReceiptUploading, receiptUploadEnabled]
  );
  const selectReceiptImage = useCallback(
    (url) => () => handleSetReceiptImageUrl(url),
    []
  );

  const downloadZip = useCallback(() => zipDeliveryReceipts(id), [id]);

  return (
    <FlexColumnCenter>
      <Detail>
        <Map row={row} />
        <small>
          만든날짜: {formatDate(row.created)} 수정날짜:
          {formatDate(row.modified)}
        </small>
        <FlexCenterSpaceBetween>
          <TitleBox>
            <h2>
              {CHANNEL[row.channel]} {row.is_isd_order ? "전화접수" : "웹접수"}{" "}
              {id}
            </h2>
            <StyledButton
              variant="contained"
              color="inherit"
              onClick={handleOrderSwap}
            >
              주문 교환
            </StyledButton>
          </TitleBox>
          <FlexBox>
            <StyledButton
              variant="contained"
              color="default"
              onClick={handleKakaoLog}
            >
              {!kakaoLog
                ? "카카오톡 발송 내역 열기"
                : "카카오톡 발송 내역 닫기"}
            </StyledButton>
            <StyledButton
              variant="contained"
              color="default"
              onClick={() =>
                handleExternalNumber(id, external_isd_order_number)
              }
            >
              지방 인성번호 접수
            </StyledButton>
            <StyledButton
              variant="contained"
              color="default"
              onClick={handleCall24Api}
            >
              전국24시콜화물 접수
            </StyledButton>
            <StyledButton
              variant="contained"
              color="secondary"
              onClick={() => handleOrderSync(row.isd_order_number)}
            >
              주문 업데이트
            </StyledButton>
          </FlexBox>
        </FlexCenterSpaceBetween>
        {kakaoLog &&
          (algoquick_kakaoreports.length > 0 ? (
            <WhiteSpacePaper>
              {algoquick_kakaoreports.map((log: KakaoReports) => (
                <div key={log.id}>
                  [{formatDate(log.created)}]{" "}
                  {log.phone_number ? log.phone_number : "번호 없음"} -{" "}
                  {STATUS[log.status]} -{" "}
                  {log.cmid
                    ? log.result
                      ? log.result
                      : "결과 확인중"
                    : "전송 안됨"}{" "}
                  {log.manual ? "[수동발송]" : null}
                </div>
              ))}
            </WhiteSpacePaper>
          ) : (
            <WhiteSpacePaper>발송 내역이 없습니다.</WhiteSpacePaper>
          ))}
        {kakaoLog && (
          <>
            <FlexBox>
              <TextField
                label="전화번호"
                type="number"
                id="kakao_phone_number"
                name="kakao_phone_number"
                value={checkField(kakao.kakao_phone_number)}
                variant="outlined"
                margin="normal"
                onChange={kakaoOnChange}
              />
              <ColumnDivider />
              <MarginTopSelectDiv>
                <WidthTextField
                  select
                  id="kakao_status"
                  name="kakao_status"
                  label="상태"
                  value={kakao.kakao_status}
                  onChange={kakaoOnChange}
                  variant="outlined"
                  width="6rem"
                >
                  {kakaoOrderStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </WidthTextField>
              </MarginTopSelectDiv>
              <ColumnDivider />
              <StyledButton
                variant="contained"
                color="secondary"
                onClick={kakaoSubmit}
              >
                카카오톡 수동 발송
              </StyledButton>
              <ColumnDivider />
            </FlexBox>
            <RowDivider />
          </>
        )}
        <hr />
        <HeaderBox>
          <FormControlLabel
            control={
              <Switch
                checked={checkField(changeable.express)}
                onChange={onChange}
              />
            }
            id="express"
            name="express"
            label="급송"
            labelPlacement="start"
          />
          <IsSafety>안심번호 사용여부</IsSafety>
          <FormControlLabel
            control={<Checkbox checked={row.is_safety_number} />}
            id="is_safety_number"
            name="is_safety_number"
            label=""
            labelPlacement="end"
            disabled
          />
        </HeaderBox>
        <FlexBox>
          {changeable.isd_order_number ? (
            <TextField
              label={ORDER_DATA_LABEL.isd_order_number}
              id="isd_order_number"
              name="isd_order_number"
              value={changeable.isd_order_number}
              variant="filled"
              margin="normal"
              onChange={onChange}
              fullWidth
            />
          ) : changeable.status === "BEFORE_WAITING" ||
            changeable.status === "REJECTION" ||
            changeable.status === "CANCELLATION" ? (
            <TextField
              label="승인 대기 / 반려 / 취소"
              id="isd_order_number"
              name="isd_order_number"
              variant="filled"
              margin="normal"
              fullWidth
              disabled
            />
          ) : (
            <InSungErrorBox>
              <WidthTextField
                label="배송번호(인성주문)"
                id="isd_order_number"
                name="isd_order_number"
                value={changeable.isd_order_number}
                variant="filled"
                margin="normal"
                onChange={onChange}
                width="70%"
                error={!changeable.isd_order_number}
                helperText="주문을 재접수 해주세요!"
              />
              <StyledButton
                variant="contained"
                color="secondary"
                size="large"
                onClick={handleReOrder}
              >
                주문 재접수
              </StyledButton>
            </InSungErrorBox>
          )}
          <ColumnDivider size="2" />
          {external_isd_order_number && (
            <>
              <TextField
                label={ORDER_DATA_LABEL.external_isd_order_number}
                id="external_isd_order_number"
                name="external_isd_order_number"
                value={changeable.external_isd_order_number}
                variant="filled"
                margin="normal"
                onChange={onChange}
                fullWidth
              />
              <ColumnDivider size="2" />
            </>
          )}
          <TextField
            label={ORDER_DATA_LABEL.call24_order_number}
            id="call24_order_number"
            name="call24_order_number"
            value={changeable.call24_order_number}
            variant="filled"
            margin="normal"
            onChange={onChange}
            fullWidth
          />
        </FlexBox>
        {corporation.name && (
          <FlexBox>
            <TextField
              label="법인명"
              id="corporation"
              name="corporation"
              value={corporation.name || " "}
              disabled
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <ColumnDivider size="2" />
            <TextField
              label="부서명"
              id="department"
              name="department"
              value={client_department || " "}
              disabled
              variant="outlined"
              margin="normal"
              fullWidth
            />
            {corporation.billing_department_enable && (
              <>
                <ColumnDivider size="2" />
                <TextField
                  label="비용 청구처"
                  id="costcenter"
                  name="costcenter"
                  value={costcenter || " "}
                  disabled
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
                {corporation.costcenter_change_memo_enable && (
                  <>
                    <ColumnDivider size="2" />
                    <TextField
                      label="비용청구부서 변경 사유"
                      id="costcenter_change_memo"
                      name="costcenter_change_memo"
                      value={costcenter_change_memo || " "}
                      disabled
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  </>
                )}
              </>
            )}
          </FlexBox>
        )}
        <FlexBox>
          <TextField
            label="의뢰인 이름"
            id="client_name"
            name="client_name"
            value={row.client_name}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="2" />
          <TextField
            label="의뢰인 연락처"
            id="client_phone_number"
            name="client_phone_number"
            value={maskingPhoneNumber(row.client_phone_number, ORDERS)}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="출발지 이름"
            id="sender_name"
            name="sender_name"
            value={checkField(row.sender_name)}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="2" />
          <TextField
            label="출발지 담당자"
            id="sender_staff"
            name="sender_staff"
            value={checkField(row.sender_staff)}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="2" />
          <TextField
            label="출발지 연락처"
            id="sender_phone_number"
            name="sender_phone_number"
            value={maskingPhoneNumber(row.sender_phone_number, ORDERS)}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="2" />
          <TextField
            label="출발지 주소"
            id="sender_jibun_address"
            name="sender_jibun_address"
            value={
              maskingAddress(row.sender_jibun_address, ORDERS) +
              " " +
              maskingAddress(row.sender_address_detail, ORDERS)
            }
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="도착지 이름"
            id="receiver_name"
            name="receiver_name"
            value={checkField(row.receiver_name)}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="2" />
          <TextField
            label="도착지 담당자"
            id="receiver_staff"
            name="receiver_staff"
            value={checkField(row.receiver_staff)}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="2" />
          <TextField
            label="도착지 연락처"
            id="receiver_phone_number"
            name="receiver_phone_number"
            value={maskingPhoneNumber(row.receiver_phone_number, ORDERS)}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="2" />
          <TextField
            label="도착지 주소"
            id="receiver_jibun_address"
            name="receiver_jibun_address"
            value={
              maskingAddress(row.receiver_jibun_address, ORDERS) +
              " " +
              maskingAddress(row.receiver_address_detail, ORDERS)
            }
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="알고퀵 출발지 도착지 거리(km)"
            id="distance"
            name="distance"
            value={(row.distance * 10) / 10000}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="2" />
          <TextField
            label="인성(네비) 출발지 도착지 거리(km)"
            id="isd_distance"
            name="isd_distance"
            value={(row.isd_distance * 10) / 10000}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="예약시간"
            id="aq_pickup_request_time"
            name="aq_pickup_request_time"
            value={pickupRequestTime || " "}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="2" />
          <TextField
            label="완료요청시간"
            id="complete_request_time"
            name="complete_request_time"
            value={formatCompleteTime(checkField(row.complete_request_time))}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="배송요금"
            id="location_charge"
            name="location_charge"
            value={changeable.location_charge}
            variant="filled"
            margin="normal"
            fullWidth
            onChange={onChange}
          />
          <ColumnDivider size="2" />
          <TextField
            label="추가요금"
            id="extra_charge"
            name="extra_charge"
            value={changeable.extra_charge}
            variant="filled"
            margin="normal"
            fullWidth
            onChange={onChange}
          />
          <ColumnDivider size="2" />
          <TextField
            label="배송실비"
            id="delivery_charge"
            name="delivery_charge"
            value={changeable.delivery_charge}
            variant="filled"
            margin="normal"
            fullWidth
            onChange={onChange}
          />
          <ColumnDivider size="2" />
          <TextField
            label="안심배송"
            id="insurance_charge"
            name="insurance_charge"
            value={row.algoquick_orders[0].insurance_charge}
            variant="filled"
            margin="normal"
            fullWidth
            disabled
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="왕복요금"
            id="round_trip_charge"
            name="round_trip_charge"
            value={extraCharge.round_trip_charge}
            variant="filled"
            margin="normal"
            fullWidth
            disabled
          />
          <ColumnDivider size="2" />
          <TextField
            label="차량요금"
            id="vehicle_charge"
            name="vehicle_charge"
            value={extraCharge.vehicle_charge}
            variant="filled"
            margin="normal"
            fullWidth
            disabled
          />
          <ColumnDivider size="2" />
          <TextField
            label="물품요금"
            id="bulk_charge"
            name="bulk_charge"
            value={extraCharge.bulk_charge}
            variant="filled"
            margin="normal"
            fullWidth
            disabled
          />
          <ColumnDivider size="2" />
          <TextField
            label="급송요금"
            id="express_charge"
            name="express_charge"
            value={extraCharge.express_charge}
            variant="filled"
            margin="normal"
            fullWidth
            disabled
          />
          <ColumnDivider size="2" />
          <TextField
            label="휴일요금"
            id="holiday_charge"
            name="holiday_charge"
            value={extraCharge.holiday_charge}
            variant="filled"
            margin="normal"
            fullWidth
            disabled
          />
          <ColumnDivider size="2" />
          <TextField
            label="시간요금"
            id="time_charge"
            name="time_charge"
            value={extraCharge.time_charge}
            variant="filled"
            margin="normal"
            fullWidth
            disabled
          />
          <ColumnDivider size="2" />
          <TextField
            label="날씨요금"
            id="weather_charge"
            name="weather_charge"
            value={extraCharge.weather_charge}
            variant="filled"
            margin="normal"
            fullWidth
            disabled
          />
          <ColumnDivider size="2" />
          <TextField
            label="상하차요금"
            id="loading_charge"
            name="loading_charge"
            value={extraCharge.loading_charge}
            variant="filled"
            margin="normal"
            fullWidth
            disabled
          />
          <ColumnDivider size="2" />
          <TextField
            label="동승요금"
            id="carpool_charge"
            name="carpool_charge"
            value={extraCharge.carpool_charge}
            variant="filled"
            margin="normal"
            fullWidth
            disabled
          />
          <ColumnDivider size="2" />
          <TextField
            label="기타요금"
            id="other_charge"
            name="other_charge"
            value={extraCharge.other_charge}
            variant="filled"
            margin="normal"
            fullWidth
            disabled
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="차량타입"
            id="vehicle_type"
            name="vehicle_type"
            value={vehicleType}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="2" />
          <TextField
            label="차량옵션"
            id="vehicle_option"
            name="vehicle_option"
            value={vehicleOption}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </FlexBox>
        {extraCharge.tid ? (
          <FlexBox>
            <OneThirdTextField
              label="배송구분"
              id="trip"
              name="trip"
              value={checkField(TRIP[row.trip])}
              disabled
              variant="outlined"
              margin="normal"
            />
            <ColumnDivider size="2" />
            <OneThirdTextField
              label="결제방법"
              id="payment"
              name="payment"
              value={checkField(PAYMENTS[row.payment])}
              disabled
              variant="outlined"
              margin="normal"
            />
            <StyledButton
              variant="contained"
              color="secondary"
              onClick={handleCancelCard}
            >
              카드 취소
            </StyledButton>
            {ORDERS?.etc1 && (
              <StyledButton
                variant="contained"
                className={classes.root}
                onClick={repaymentCard}
              >
                카드 재결제
              </StyledButton>
            )}
          </FlexBox>
        ) : (
          <FlexBox>
            <TextField
              label="배송구분"
              id="trip"
              name="trip"
              value={checkField(TRIP[row.trip])}
              disabled
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <ColumnDivider size="2" />
            <TextField
              label="결제방법"
              id="payment"
              name="payment"
              value={checkField(PAYMENTS[row.payment])}
              disabled
              variant="outlined"
              margin="normal"
              fullWidth
            />
          </FlexBox>
        )}
        {corporate_account_memo && (
          <TextField
            label="배송사유"
            id="corporate_account_memo"
            name="corporate_account_memo"
            value={corporate_account_memo}
            variant="outlined"
            margin="normal"
            fullWidth
            multiline={true}
            disabled
          />
        )}
        <TextField
          label="메모"
          id="memo"
          name="memo"
          value={checkField(row.memo)}
          disabled
          variant="outlined"
          margin="normal"
          fullWidth
          multiline={true}
        />
        <TextField
          label="적요란"
          id="note"
          name="note"
          value={changeable.note}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={onChange}
          multiline={true}
        />
        <FlexBox>
          <OneFifthTextField
            label="기사이름"
            id="rider_name"
            name="rider_name"
            value={changeable.rider_name}
            onChange={onChange}
            variant="outlined"
            margin="normal"
          />
          <ColumnDivider />
          <OneFifthTextField
            label="기사 연락처"
            id="rider_phone_number"
            name="rider_phone_number"
            value={changeable.rider_phone_number}
            onChange={onChange}
            variant="outlined"
            margin="normal"
          />
          <ColumnDivider />
          <OneFifthTextField
            label="기사 차량 번호"
            id="rider_vehicle_number"
            name="rider_vehicle_number"
            value={checkField(row.rider_vehicle_number)}
            variant="outlined"
            margin="normal"
            disabled
          />
          <StyledButton
            variant="contained"
            size="large"
            disabled={!rider_phone_number}
            onClick={handleSendReceiptKakao}
          >
            인수증 문자 전송
          </StyledButton>
          <Tooltip
            title={`저장 가능한 인수증의 최대 개수는 ${receiptFileMaxCnt}장입니다.`}
            placement="bottom"
            PopperProps={{
              popperOptions: {
                modifiers: {
                  offset: {
                    enabled: true,
                    offset: "0px, -10px",
                  },
                },
              },
            }}
            disableFocusListener={receiptUploadEnabled}
            disableHoverListener={receiptUploadEnabled}
            disableTouchListener={receiptUploadEnabled}
          >
            <label htmlFor="delivery-receipt">
              <StyledButton
                component="span"
                variant="contained"
                size="large"
                style={{ marginBottom: "0 !important" }}
                disabled={receiptUploadDisabled}
              >
                {receiptImages.length > 0 ? "인수증 추가" : "인수증 첨부"}
              </StyledButton>
            </label>
          </Tooltip>
          <Input
            id="delivery-receipt"
            type="file"
            style={{ display: "none" }}
            inputProps={{
              accept: "image/*",
              disabled: receiptUploadDisabled,
              multiple: true,
            }}
            onChange={handleUploadReceiptFile}
          />
          <StyledButton
            variant="contained"
            size="large"
            disabled={receiptImages.length === 0}
            onClick={handleToggleIsReceiptDialogOpen}
          >
            인수증 확인
          </StyledButton>
        </FlexBox>
        <FlexBox>
          <FlexBoxWith100>
            <QuarterTextField
              label="관련회원"
              id="relatedUsername"
              name="relatedUsername"
              value={usersUserByRelatedUserId?.username || ""}
              disabled
              variant="outlined"
              margin="normal"
              size="medium"
            />
            <ColumnDivider />
            <QuarterTextField
              label="접수회원"
              id="username"
              name="username"
              value={orderUser}
              variant="outlined"
              margin="normal"
              onChange={(e) => setOrderUser(e.target.value)}
              disabled={!inputOpen}
            />
            {inputOpen ? (
              <>
                <StyledButton
                  variant="outlined"
                  size="large"
                  onClick={closeOrderUser}
                >
                  닫기
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={submitOrderUser}
                >
                  접수회원 변경
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={deleteOrderUser}
                >
                  접수회원 삭제
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={deleteRelatedUser}
                >
                  관련회원 삭제
                </StyledButton>
              </>
            ) : (
              <StyledButton
                variant="contained"
                color="secondary"
                size="large"
                onClick={() => setInputOpen(true)}
              >
                회원정보 연동
              </StyledButton>
            )}
            {rider_phone_number && (
              <StyledButton
                variant="contained"
                color="default"
                size="large"
                onClick={() => setTextMessageDialogOpen(true)}
              >
                배차문자 전송
              </StyledButton>
            )}
          </FlexBoxWith100>
        </FlexBox>
        <RowDivider />
        <FlexBox>
          <HalfTextField
            select
            id="status"
            name="status"
            label="상태"
            value={changeable.status}
            onChange={onChange}
            variant="filled"
          >
            {orderStatus.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </HalfTextField>
          <ColumnDivider />
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={openUseStateModal}
            style={{ height: "3rem" }}
            disabled={useStatementDisabled}
          >
            거래 내역서
          </Button>
          <HelpText>
            *승인대기 / 반려 / 취소 주문은 PDF 파일을 받을 수 없습니다.
          </HelpText>
        </FlexBox>
        <RowDivider />
        <hr />
        <RowDivider />
        <FlexCenterSpaceBetween>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => history.goBack()}
          >
            취소
          </Button>
          <Button variant="outlined" color="primary" onClick={onSubmit}>
            저장
          </Button>
        </FlexCenterSpaceBetween>
      </Detail>

      <SendTextMessageDialog
        open={isTextMessageDialogOpen}
        onClose={handleCloseDialog}
        onChange={handleChangeDialog}
        onSubmit={sendTextMessage}
        text={textMessage}
      />
      {/* 인수증 확인 Dialog */}
      <Dialog
        open={isReceiptDialogOpen}
        onClose={handleToggleIsReceiptDialogOpen}
        maxWidth="md"
      >
        <DialogTitle>
          인수증
          <IconButton
            size="small"
            className={classes.floatRight}
            onClick={handleToggleIsReceiptDialogOpen}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className={classes.flex}>
          <div className={classes.alignCenter}>
            {receiptImageUrl && (
              <img
                src={receiptImageUrl}
                alt="인수증"
                className={classes.sizedImage}
              />
            )}
          </div>
          <List
            subheader={
              <ListSubheader component="div" id="subheader" disableSticky>
                인수증을 선택해주세요.
              </ListSubheader>
            }
          >
            {receiptImages.map(({ filename, url }: ReceiptImgType) => (
              <ListItem
                key={filename}
                selected={receiptImageUrl === url}
                button
                onClick={selectReceiptImage(url)}
              >
                <ListItemText primary={filename} />
                <ListItemSecondaryAction>
                  {deletingReceiptFilename === filename ? (
                    <CircularProgress size="1.25rem" color="inherit" />
                  ) : (
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      disabled={deletingReceiptFilename !== ""}
                      onClick={handleDeleteReceiptFile({ filename, url })}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <StyledButton
            variant="contained"
            size="large"
            color="secondary"
            onClick={downloadZip}
          >
            전체 다운로드
          </StyledButton>
        </DialogActions>
      </Dialog>
    </FlexColumnCenter>
  );
}

export default OrderDetail;

const useStyles = makeStyles(() => ({
  root: { color: "#fff", backgroundColor: "#ffa500" },
  floatRight: { padding: "4px", float: "right" },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    width: "750px",
    height: "500px",
  },
  alignCenter: { flex: "0 0 450px", textAlign: "center" },
  sizedImage: { maxWidth: "100%", maxHeight: "100%" },
}));

const StyledButton = styled(Button)<ButtonProps>`
  margin: 1rem 0.5rem !important;
  height: 3rem !important;
`;

const InSungErrorBox = styled(FlexBoxWith100)`
  border: red 2px solid;
  padding: 0 1rem;
`;

const TitleBox = styled(FlexBox)`
  h2 {
    margin: auto 0;
  }
`;

const HeaderBox = styled(FlexBox)`
  height: 40px;
`;

const IsSafety = styled.div`
  margin: 0 6px 0 16px;
  line-height: 43px;
`;

const HelpText = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  height: 3rem;
  font-size: 15px;
  font-weight: 600;
  color: #989898;
`;
