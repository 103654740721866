import { Dialog, DialogContent } from "@material-ui/core";
import React from "react";
import { StyledDialogTitle } from "../../components/common/Modal";
import CancelCard from "../../components/order/CancelCard";

type CancelCardProps = {
  cancelCardPopup: boolean;
  setCancelCardPopup: (card: boolean) => void;
  card: {
    id: string;
    tid: string;
    orderCharge: string;
    charge: string;
    cancelMessage: string;
    code: string;
  };
  handleCancelCardChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  submitCancelCard: () => void;
};

function CancelCardContainer({
  cancelCardPopup,
  setCancelCardPopup,
  card,
  handleCancelCardChange,
  submitCancelCard,
}: CancelCardProps) {
  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setCancelCardPopup(false);
  };

  return (
    <Dialog
      open={cancelCardPopup}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StyledDialogTitle id="alert-dialog-title">{`${card.id} 카드 취소`}</StyledDialogTitle>
      <DialogContent>
        <CancelCard
          card={card}
          handleCancelCardChange={handleCancelCardChange}
          submitCancelCard={submitCancelCard}
          handleClose={handleClose}
        />
      </DialogContent>
    </Dialog>
  );
}

export default CancelCardContainer;
