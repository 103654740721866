import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Button, TextField } from "@material-ui/core";

import Detail from "../common/Detail";
import { BulkType } from "../../containers/corporation/types";

import {
  FlexCenterSpaceBetween,
  FlexColumnCenter,
  MarginAutoDiv,
  RowDivider,
} from "../common/Base";
import { checkField } from "../../lib/validate";
import { QuarterTextField } from "../common/StyledTextField";

interface BulkProps {
  row: BulkType;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: React.FormEvent) => void;
  resetBulk: () => void;
}

const CorporationBulk = ({ row, onChange, onSubmit, resetBulk }: BulkProps) => {
  const history = useHistory();
  const {
    big_sack,
    medium_box,
    big_shopping_bag,
    oversize_big_box,
    small_box,
    big_box,
    corporations_corporation,
    shopping_bag,
    small_sack,
  } = row;

  return (
    <FlexColumnCenter>
      <Detail>
        <TextField
          label="법인 이름"
          id="name"
          name="name"
          value={corporations_corporation?.name}
          disabled
          variant="outlined"
          margin="normal"
          fullWidth
        />
        <FlexCenterSpaceBetween>
          <QuarterTextField
            label="기본 쇼핑백 점수"
            type="number"
            id="base_shopping_bag"
            name="base_shopping_bag"
            value={0}
            variant="outlined"
            margin="normal"
            disabled
          />
          <StyledMarginAutoDiv> + </StyledMarginAutoDiv>
          <QuarterTextField
            label="쇼핑백 점수"
            type="number"
            id="shopping_bag"
            name="shopping_bag"
            value={checkField(shopping_bag)}
            variant="outlined"
            margin="normal"
            onChange={onChange}
          />
          <StyledMarginAutoDiv> = </StyledMarginAutoDiv>
          <QuarterTextField
            label="합계 쇼핑백 점수"
            type="number"
            id="total_shopping_bag"
            name="total_shopping_bag"
            value={checkField(shopping_bag) + 0}
            variant="outlined"
            margin="normal"
            disabled
          />
        </FlexCenterSpaceBetween>
        <FlexCenterSpaceBetween>
          <QuarterTextField
            label="기본 대형 쇼핑백 점수"
            type="number"
            id="base_big_shopping_bag"
            name="base_big_shopping_bag"
            value={3}
            variant="outlined"
            margin="normal"
            disabled
          />
          <StyledMarginAutoDiv> + </StyledMarginAutoDiv>
          <QuarterTextField
            label="대형 쇼핑백 점수"
            type="number"
            id="big_shopping_bag"
            name="big_shopping_bag"
            value={checkField(big_shopping_bag)}
            variant="outlined"
            margin="normal"
            onChange={onChange}
          />
          <StyledMarginAutoDiv> = </StyledMarginAutoDiv>
          <QuarterTextField
            label="합계 대형 쇼핑백 점수"
            type="number"
            id="total_big_shopping_bag"
            name="total_big_shopping_bag"
            value={checkField(big_shopping_bag) + 1.5}
            variant="outlined"
            margin="normal"
            disabled
          />
        </FlexCenterSpaceBetween>
        <FlexCenterSpaceBetween>
          <QuarterTextField
            label="기본 소박스 점수"
            type="number"
            id="base_small_box"
            name="base_small_box"
            value={1.5}
            variant="outlined"
            margin="normal"
            disabled
          />
          <StyledMarginAutoDiv> + </StyledMarginAutoDiv>
          <QuarterTextField
            label="소박스 점수"
            type="number"
            id="small_box"
            name="small_box"
            value={checkField(small_box)}
            variant="outlined"
            margin="normal"
            onChange={onChange}
          />
          <StyledMarginAutoDiv> = </StyledMarginAutoDiv>
          <QuarterTextField
            label="합계 소박스 점수"
            type="number"
            id="total_small_box"
            name="total_small_box"
            value={checkField(small_box) + 1.5}
            variant="outlined"
            margin="normal"
            disabled
          />
        </FlexCenterSpaceBetween>
        <FlexCenterSpaceBetween>
          <QuarterTextField
            label="기본 중박스 점수"
            type="number"
            id="base_medium_box"
            name="base_medium_box"
            value={2.5}
            variant="outlined"
            margin="normal"
            disabled
          />
          <StyledMarginAutoDiv> + </StyledMarginAutoDiv>
          <QuarterTextField
            label="중박스 점수"
            type="number"
            id="medium_box"
            name="medium_box"
            value={checkField(medium_box)}
            variant="outlined"
            margin="normal"
            onChange={onChange}
          />
          <StyledMarginAutoDiv> = </StyledMarginAutoDiv>
          <QuarterTextField
            label="합계 중박스 점수"
            type="number"
            id="total_medium_box"
            name="total_medium_box"
            value={checkField(medium_box) + 2.5}
            variant="outlined"
            margin="normal"
            disabled
          />
        </FlexCenterSpaceBetween>
        <FlexCenterSpaceBetween>
          <QuarterTextField
            label="기본 대박스 점수"
            type="number"
            id="base_big_box"
            name="base_big_box"
            value={4.0}
            variant="outlined"
            margin="normal"
            disabled
          />
          <StyledMarginAutoDiv> + </StyledMarginAutoDiv>
          <QuarterTextField
            label="대박스 점수"
            type="number"
            id="big_box"
            name="big_box"
            value={checkField(big_box)}
            variant="outlined"
            margin="normal"
            onChange={onChange}
          />
          <StyledMarginAutoDiv> = </StyledMarginAutoDiv>
          <QuarterTextField
            label="합계 대박스 점수"
            type="number"
            id="total_big_box"
            name="total_big_box"
            value={checkField(big_box) + 4.0}
            variant="outlined"
            margin="normal"
            disabled
          />
        </FlexCenterSpaceBetween>
        <FlexCenterSpaceBetween>
          <QuarterTextField
            label="기본 특대 박스 점수"
            type="number"
            id="base_oversize_big_box"
            name="base_oversize_big_box"
            value={3.0}
            variant="outlined"
            margin="normal"
            disabled
          />
          <StyledMarginAutoDiv> + </StyledMarginAutoDiv>
          <QuarterTextField
            label="특대 박스 점수"
            type="number"
            id="oversize_big_box"
            name="oversize_big_box"
            value={checkField(oversize_big_box)}
            variant="outlined"
            margin="normal"
            onChange={onChange}
          />
          <StyledMarginAutoDiv> = </StyledMarginAutoDiv>
          <QuarterTextField
            label="합계 특대 박스 점수"
            type="number"
            id="total_oversize_big_box"
            name="total_oversize_big_box"
            value={checkField(oversize_big_box) + 3.0}
            variant="outlined"
            margin="normal"
            disabled
          />
        </FlexCenterSpaceBetween>
        <FlexCenterSpaceBetween>
          <QuarterTextField
            label="기본 소봉투 점수"
            type="number"
            id="base_small_sack"
            name="base_small_sack"
            value={1.0}
            variant="outlined"
            margin="normal"
            disabled
          />
          <StyledMarginAutoDiv> + </StyledMarginAutoDiv>
          <QuarterTextField
            label="소봉투 점수"
            type="number"
            id="small_sack"
            name="small_sack"
            value={checkField(small_sack)}
            variant="outlined"
            margin="normal"
            onChange={onChange}
          />
          <StyledMarginAutoDiv> = </StyledMarginAutoDiv>
          <QuarterTextField
            label="합계 소봉투 점수"
            type="number"
            id="total_small_sack"
            name="total_small_sack"
            value={checkField(small_sack) + 1.0}
            variant="outlined"
            margin="normal"
            disabled
          />
        </FlexCenterSpaceBetween>
        <FlexCenterSpaceBetween>
          <QuarterTextField
            label="기본 대봉투 점수"
            type="number"
            id="base_big_sack"
            name="base_big_sack"
            value={2.5}
            variant="outlined"
            margin="normal"
            disabled
          />
          <StyledMarginAutoDiv> + </StyledMarginAutoDiv>
          <QuarterTextField
            label="대봉투 점수"
            type="number"
            id="big_sack"
            name="big_sack"
            value={checkField(big_sack)}
            variant="outlined"
            margin="normal"
            onChange={onChange}
          />
          <StyledMarginAutoDiv> = </StyledMarginAutoDiv>
          <QuarterTextField
            label="합계 대봉투 점수"
            type="number"
            id="total_big_sack"
            name="total_big_sack"
            value={checkField(big_sack) + 2.5}
            variant="outlined"
            margin="normal"
            disabled
          />
        </FlexCenterSpaceBetween>
        <RowDivider />
        <FlexCenterSpaceBetween>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => history.goBack()}
          >
            취소
          </Button>
          <Button variant="outlined" color="secondary" onClick={resetBulk}>
            초기화
          </Button>
          <Button variant="outlined" color="primary" onClick={onSubmit}>
            저장
          </Button>
        </FlexCenterSpaceBetween>
      </Detail>
    </FlexColumnCenter>
  );
};

export default CorporationBulk;

const StyledMarginAutoDiv = styled(MarginAutoDiv)`
  font-size: 2rem;
  font-weight: 900;
`;
