import { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Button, Snackbar } from "@material-ui/core";
import useSnackbar from "../../hooks/useSnackbar";
import ExtraRoundTripChargeList from "../../components/corporation/ExtraRoundTripChargeList";
import NoExtraChargeList from "../../components/corporation/NoExtraChargeList";
import Notice from "../../components/common/Notice";
import { destructResponse } from "../../lib/hasura/common";
import {
  getCorpRoundTripCharge,
  createCorpRoundTripCharge,
  updateCorpRoundTripCharge,
  deleteCorpRoundTripCharge,
} from "../../lib/hasura/extraCharge";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import { RoundTripType } from "./extraTypes";
import { validateroundTripCharge } from "./utils";

interface RoundTripChargeContainerProps {
  corpId: string;
  corpName: string;
}

function CorporationRoundTripChargeContainer({
  corpId,
  corpName,
}: RoundTripChargeContainerProps) {
  const dispatch = useDispatch();
  const [addRoundTrip, setAddRoundTrip] = useState({}); // 등록할 왕복요금
  const [roundTripData, setRoundTripData] = useState<RoundTripType[]>([]); // 왕복요금 데이터

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  // mounted
  useEffect(() => {
    fetchRoundTripData();
  }, [corpId]);

  const fetchRoundTripData = async () => {
    dispatch(startGlobalLoading());
    try {
      const response = await destructResponse<RoundTripType[]>(
        "corporations_corporationroundtripcharge",
        () => getCorpRoundTripCharge(corpId)
      );
      setRoundTripData(response);
    } catch (err) {
      console.log(err);
      openSnackbar("왕복요금을 불러오는데 실패하였습니다.", true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  // 전체 왕복요금 생성
  const onAllRegist = async () => {
    const confirmMsg = `${corpName}의 왕복할증을 생성하시겠습니까?`;
    if (!confirm(confirmMsg)) return;

    dispatch(startGlobalLoading());
    try {
      await createCorpRoundTripCharge(corpId);
      await fetchRoundTripData();
    } catch (err) {
      const failMsg = (err as Error).message || "할증 등록에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddRoundTrip({
      ...addRoundTrip,
      [name]: Number(value),
    });
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const confirmMsg = `왕복 할증룰을 등록하시겠습니까?`;
    if (!confirm(confirmMsg)) return;

    dispatch(startGlobalLoading());
    try {
      /**
       * @description onChange함수를 통해 입력받은 { [name]: value } 객체를
       * @return { [{ vehicle_type: name, round_trip_charge_rate: value }] } 형태로 변환
       */
      const refinedAddData: RoundTripType[] = Object.entries(addRoundTrip).map(
        ([vehicle_type, round_trip_charge_rate]) => ({
          vehicle_type,
          round_trip_charge_rate: round_trip_charge_rate as number,
        })
      );
      refinedAddData.map((el) => {
        const invalidMessage = validateroundTripCharge(el);
        if (invalidMessage) throw new Error(invalidMessage);
      });

      const promise = refinedAddData.map(
        async (el) => await updateCorpRoundTripCharge(corpId, el)
      );
      const result = await Promise.allSettled(promise);
      await fetchRoundTripData();

      const isRejected = result.some((el) => el.status === "rejected");
      if (isRejected) throw new Error("등록에 실패한 요금이 있습니다.");

      openSnackbar("요금이 등록되었습니다.", false);
    } catch (err) {
      console.log(err);
      const failMsg = (err as Error).message || "요금 등록에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const onAllDelete = async () => {
    const deleteMsg = `왕복할증을 전체 삭제하시곘습니까?\n전체 삭제시 "기존 왕복요금"으로 자동 적용됩니다.`;
    if (!confirm(deleteMsg)) return;

    dispatch(startGlobalLoading());
    try {
      await deleteCorpRoundTripCharge(corpId);
      await fetchRoundTripData();
      openSnackbar("삭제가 완료되었습니다.", false);
    } catch (err) {
      const failMsg = (err as Error).message || "할증 삭제에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return;
    closeSnackbar();
  };

  return (
    <>
      <RoundTripWrapper>
        <RoundTripHeader>
          <h1 className="title">왕복할증 DB화 ({corpName})</h1>
          <div className="description">
            *기본요금에 대한 차량별 할증률을 적용합니다.
          </div>
        </RoundTripHeader>
        {roundTripData.length > 0 ? (
          <RoundTripBody>
            <ExtraRoundTripChargeList
              roundTripData={roundTripData}
              onChange={onChange}
            />
            <ButtonWrapper>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                할증 등록
              </StyledButton>
              <StyledButton
                variant="contained"
                color="secondary"
                onClick={onAllDelete}
              >
                전체 삭제
              </StyledButton>
            </ButtonWrapper>
          </RoundTripBody>
        ) : (
          <NoExtraChargeList type={"왕복"} onAllRegist={onAllRegist} />
        )}
      </RoundTripWrapper>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}

export default CorporationRoundTripChargeContainer;

const RoundTripWrapper = styled.section`
  margin-top: 20px;
  text-align: center;
`;

const RoundTripHeader = styled.header`
  padding-bottom: 1.5rem;

  .title {
    margin: 10px;
    font-size: 1.7rem;
  }
  .description {
    font-size: 18px;
    font-weight: 800;
    color: #9e9e9e;
  }
`;

const RoundTripBody = styled.section`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.section`
  display: flex;
  margin: 1rem auto;
  gap: 4rem;
`;

const StyledButton = styled(Button)`
  margin: 2rem auto !important;
  width: 10rem;
  height: 3rem;
  font-size: 1rem;
`;
