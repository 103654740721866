import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { sleep } from "../../lib/core";
import CorporationRegist from "../corporation/CorporationRegist";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import { DepartState } from "../../containers/department/types";
import { UserState } from "../../containers/user/types";
import {
  CorpProps,
  CorporateProject,
} from "../../containers/corporation/types";
import {
  createDepartByCorp,
  createProject,
} from "../../lib/hasura/departments";
import { registUser } from "../../lib/api/users";
import { WhiteSpacePaper } from "../common/MaterialBase";
import { RowDivider } from "../common/Base";

interface ManageCorporateUploadProps {
  corp: CorpProps;
  deptOpened: boolean;
  userOpened: boolean;
  projectOpened: boolean;
}

function ManageCorporateUpload({
  corp,
  deptOpened = false,
  userOpened = false,
  projectOpened = false,
}: ManageCorporateUploadProps) {
  const dispatch = useDispatch();

  // 부서등록, 회원등록, 프로젝트등록
  const [departs, setDeparts] = useState<DepartState[]>([]);
  const [users, setUsers] = useState<UserState[]>([]);
  const [projects, setProjects] = useState<CorporateProject[]>([]);

  const [upload, setUpload] = useState(false);
  const [fileName, setFileName] = useState("");
  const [log, setLog] = useState(""); // 결과 로그 스택

  useEffect(() => {
    setDeparts([]);
    setUsers([]);
    setProjects([]);
    setUpload(false);
    setFileName("");
    setLog("");
  }, [deptOpened, userOpened, projectOpened]);

  const notVisible = [deptOpened, userOpened, projectOpened].every(
    (opened) => !opened
  );
  if (notVisible) return <></>;

  /**
   * @description 엑셀 업로드 공통 함수
   * @param { SetStateAction } setState 부서, 회원, 프로젝트 업로드 상태
   */
  const handleUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    setState: React.SetStateAction<any>
  ) => {
    const files = e.target.files;

    if (files && files[0]) {
      handleFile(files[0], setState);
      setFileName(files[0].name);
      setUpload(true);
    }
  };
  const handleFile = (file: File, setState: React.SetStateAction<any>) => {
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const data = e.target && e.target.result;

      const workbook = XLSX.read(data, { type: "array" });
      const worksheetName = workbook.SheetNames[0];
      const workSheet = workbook.Sheets[worksheetName];
      const result: DepartState[] = XLSX.utils.sheet_to_json(workSheet, {
        // header: 1,
        defval: "",
        blankrows: false,
      });

      setState(result);
    };
    reader.readAsArrayBuffer(file);
  };

  // 부서 등록
  const departSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(startGlobalLoading());

    let logs = "부서 등록을 진행합니다.\n\n";
    for (let key = 0; key < departs.length; key++) {
      let depart = departs[key];
      const requestDepart = {
        ...depart,
        corporation: corp.id,
      };

      try {
        const {
          data: { errors },
        } = await createDepartByCorp(requestDepart);
        if (errors !== undefined) throw new Error(errors[0].message);

        logs += `[성공] 부서명: ${depart.name}\n`;
      } catch (err) {
        logs += `[실패] 부서명: ${depart.name} -> ${err}\n`;
      } finally {
        setLog(logs);
        await sleep(500);
      }
    }
    setLog((logs += "진행완료"));
    dispatch(finishGlobalLoading());
  };

  // 회원 등록
  const userSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(startGlobalLoading());

    let logs = "회원 가입을 진행합니다.\n\n";
    for (let key = 0; key < users.length; key++) {
      let user = users[key];

      try {
        // 법인 회원가입
        // 이메일 기재시 "법인 이메일 인증"과 동일하게 사번(employeeNumber) 등록
        const email = user.email;
        const employeeNumber = email?.split("@")[0] || null;

        const {
          data: { id },
        } = await registUser({
          corporation_signup: true,
          username: user.username,
          password: user.password,
          passwordConfirm: user.password,
          fullname: user.fullname,
          phone: user.phone.toString(),
          email,
          employeeNumber,
          company_name: corp.name,
          department_name: user.department,
        });

        if (id) logs += `[성공] 아이디: ${user.username}\n`;
      } catch (err) {
        logs += `[실패] 아이디: ${user.username} -> ${err}\n`;
      } finally {
        setLog(logs);
        await sleep(500);
      }
    }
    setLog((logs += "진행완료"));
    dispatch(finishGlobalLoading());
  };

  // 프로젝트 등록
  const projectSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(startGlobalLoading());

    let logs = "프로젝트 등록을 진행합니다.\n\n";
    for (let key = 0; key < projects.length; key++) {
      let project = projects[key];
      const requestProject = {
        ...project,
        corporation: corp.id,
      };

      try {
        const {
          data: { errors },
        } = await createProject(requestProject);
        if (errors !== undefined) throw new Error(errors[0].message);

        logs += `[성공] 프로젝트명: ${project.name}\n`;
      } catch (err) {
        logs += `[실패] 프로젝트명: ${project.name} -> ${err}\n`;
      } finally {
        setLog(logs);
        await sleep(500);
      }
    }
    setLog((logs += "진행완료"));
    dispatch(finishGlobalLoading());
  };

  return (
    <Box>
      {deptOpened && (
        <CorporationRegist
          data={departs}
          upload={upload}
          fileName={fileName}
          handleUpload={(e) => handleUpload(e, setDeparts)}
          onSubmit={departSubmit}
          regist="depart"
        />
      )}
      {userOpened && (
        <CorporationRegist
          data={users}
          upload={upload}
          fileName={fileName}
          handleUpload={(e) => handleUpload(e, setUsers)}
          onSubmit={userSubmit}
          regist="user"
        />
      )}
      {projectOpened && (
        <CorporationRegist
          data={projects}
          upload={upload}
          fileName={fileName}
          handleUpload={(e) => handleUpload(e, setProjects)}
          onSubmit={projectSubmit}
          regist="project"
        />
      )}
      {log && <WhiteSpacePaper>{log}</WhiteSpacePaper>}
      <RowDivider size="3" />
    </Box>
  );
}

export default React.memo(ManageCorporateUpload);

const Box = styled.div`
  padding: 1rem 0 0;
`;
