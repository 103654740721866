import React, { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import Description from "./LargeChargeDescription";
import ExcelFileDown from "../../components/sendBill/ExcelFileDown";
import CustomExcelUpDownload from "../../lib/excel/CustomExcelUpDownload";

import { LargeChargeCalculate } from "./LargeChargeCalculate";
import { ChannelData } from "../../lib/constants/constants";
import { GroupWrapper, ButtonWideWrapper } from "../../pages/GroupOrder/style";

function LargeChargeContainer() {
  const [channel, setChannel] = useState("algoquick");

  const modifyChannel = ChannelData.filter(({ key }) =>
    key.includes("algoquick")
  );

  const sampleDownload = (e: React.MouseEvent) => {
    e.preventDefault();
    const link = document.createElement("a");
    link.href =
      "https://algoquick-email-asset.s3.ap-northeast-2.amazonaws.com/large_charge_sample_241014.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GroupWrapper>
      <h1>EXCEL 대량요금조회</h1>
      <Description />
      <ButtonWideWrapper>
        <FormControl margin={"normal"}>
          <InputLabel id="channel-select-label">채널</InputLabel>
          <Select
            labelId="channel-select-label"
            id="channel-select"
            value={channel}
            onChange={(e) => setChannel(e.target.value as string)}
          >
            {modifyChannel.map((option) => (
              <MenuItem key={option.key} value={option.key}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <ExcelFileDown onClick={sampleDownload} />
        <CustomExcelUpDownload
          channel={channel}
          calculateFunction={LargeChargeCalculate}
        />
      </ButtonWideWrapper>
    </GroupWrapper>
  );
}

export default LargeChargeContainer;
