import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, withRouter, RouteComponentProps } from "react-router-dom";
import { Snackbar } from "@material-ui/core";

import CorporationBulk from "../../components/corporation/CorporationBulk";
import Notice from "../../components/common/Notice";
import useSnackbar from "../../hooks/useSnackbar";

import { destructResponse } from "../../lib/hasura/common";
import { getCorpBulk, updateCorpBulk } from "../../lib/hasura/corporations";
import { DEFAULT_BULK_DATA, BulkType } from "./types";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";

const CorporationBulkContainer = ({
  match,
}: RouteComponentProps<{ id: string }>) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const corpId = match.params.id;

  const [bulk, setBulk] = useState<BulkType>(DEFAULT_BULK_DATA);

  const allowedGoBack = useRef(false);
  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  // mounted
  useEffect(() => {
    fetchData();
  }, [corpId]);

  const fetchData = async () => {
    dispatch(startGlobalLoading());
    try {
      const response = await destructResponse<BulkType>(
        "corporations_motorcyclebulkscore_by_pk",
        () => getCorpBulk(corpId)
      );

      setBulk(response);
    } catch (err) {
      console.log(err);
      openSnackbar(
        "과적 포인트 조회를 실패했습니다. 개발팀에 문의해주세요.",
        true
      );
      allowedGoBack.current = true;
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setBulk({
      ...bulk,
      [name]: parseFloat(value),
    });
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(startGlobalLoading());
    try {
      const {
        data: { errors },
      } = await updateCorpBulk(bulk);
      if (errors !== undefined) throw new Error(errors[0].message);

      openSnackbar("변경완료되었습니다.");
      allowedGoBack.current = true;
    } catch (err) {
      openSnackbar(`변경실패하였습니다.\n${(err as Error).message}`, true);
      allowedGoBack.current = false;
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const resetBulk = () => {
    setBulk({
      ...bulk,
      big_box: 3,
      big_sack: 5,
      big_shopping_bag: 2,
      medium_box: 2.5,
      oversize_big_box: 5,
      shopping_bag: 1.5,
      small_box: 1.5,
      small_sack: 2,
    });
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    closeSnackbar();
    if (allowedGoBack.current) history.push(`/corporation/${corpId}`);
  };

  return (
    <>
      {bulk?.corporation_id && (
        <CorporationBulk
          row={bulk}
          onChange={onChange}
          onSubmit={onSubmit}
          resetBulk={resetBulk}
        />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
};

export default withRouter(CorporationBulkContainer);
