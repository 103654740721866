import graphqlQuery from "./base";
import { uuidV4 } from "../core";
import {
  CorpProps,
  ExcelColumnOption,
  BulkType,
} from "../../containers/corporation/types";
import { EXCEL_COLUMNS, ChannelMenu } from "../constants/constants";
import { getChannelQuery } from "./orders";

export const getCorporations = (channel: ChannelMenu) =>
  graphqlQuery(`{
    corporations_corporation(
      where: ${getChannelQuery(channel)}
    ) {
        id
        name      
      }
    }
  `);

export const getCorporationsWithDepartments = (channel: ChannelMenu) =>
  graphqlQuery(`{
    corporations_corporation(
      where: ${getChannelQuery(channel)}
    ) {
      id
      name
      channel
      corporations_departments {
        id
        name
      }      
    }}
  `);

export const getCorporationsSearch = (search: string, channel: ChannelMenu) =>
  graphqlQuery(`{
    corporations_corporation(
      where: {
        _and: [
          ${getChannelQuery(channel)},
          {name: {_ilike: "%${search}%"}}
        ]
      }) {
      id
      name
      channel
      phone
      address
      registration_number
      contracted
      corporate_account_enable
      discount_rate
      users_user {
        username
      }
    }}
  `);

export const getSingleCorp = async () =>
  await graphqlQuery(`{
    corporations_corporation{
      id
      name
    }}
  `);

export const getSingleCorpCharge = async (search: string) =>
  await graphqlQuery(`{
    corporations_corporationcharge(
      where: {corporations_corporation: {id: {_eq: "${search}"}}},
      order_by: {vehicle_type: desc, departure_id: asc, destination_id: asc}
      ) {
      id
      created
      modified
      vehicle_type
      charge
      AlgoQuickCharge_id
      corporation_id
      departure_id
      destination_id
      charge_algoquickcharge {
        motorcycle
        damas
        labo
        truck_11t
        truck_14t
        truck_15t
        truck_18t
        truck_1_4t
        truck_1t
        truck_25t
        truck_2_5t
        truck_3_5t
        truck_5t
        truck_8t
      }
      chargeLocationl3ByDepartureId {
        charge_locationl1 {
          name
        }
        charge_locationl2 {
          name
        }
        name
      }
      charge_locationl3 {
        charge_locationl1 {
          name
        }
        charge_locationl2 {
          name
        }
        name
      }
    }
    corporations_corporationnetworkcharge(
      where: {
        corporation_id: {_eq: "${search}"}
    }) {
      id
      departure_id
      chargeLocationl3ByDepartureId {
        charge_locationl1 {
          name
        }
        charge_locationl2 {
          name
        }
        name
      }
      destination_id
      chargeLocationl3ByDestinationId {
        charge_locationl1 {
          name
        }
        charge_locationl2 {
          name
        }
        name
      }
      vehicle_type
      vehicle_option
      network_charge
    }
}`);

export const createSingleCorpCharge = async (data: {
  corporation_id: string;
  departure_id: string;
  destination_id: string;
  vehicle_type: string;
  AlgoQuickCharge_id: string;
  charge: number;
}) => {
  const timeCheck = new Date().toISOString();
  return await graphqlQuery(`
    mutation {
      insert_corporations_corporationcharge(
        objects: {
          corporation_id: "${data.corporation_id}",
          departure_id: "${data.departure_id}",
          destination_id: "${data.destination_id}",
          vehicle_type: "${data.vehicle_type}",
          AlgoQuickCharge_id: "${data.AlgoQuickCharge_id}",
          charge: ${data.charge},
          created: "${timeCheck}",
          modified: "${timeCheck}",
        }) {
        returning {
          corporation_id
        }
      }
    }
  `);
};

export const createNetworkSingleCorpCharge = async (data: {
  corporation_id: string;
  departure_id: string;
  destination_id: string;
  vehicle_type: string;
  charge: number;
}) => {
  const timeCheck = new Date().toISOString();
  return graphqlQuery(`
    mutation {
      insert_corporations_corporationnetworkcharge(
        objects: {
          corporation_id: "${data.corporation_id}",
          created: "${timeCheck}",
          modified: "${timeCheck}",
          charge_id: "${data.departure_id + data.destination_id}",
          departure_id: "${data.departure_id}",
          destination_id: "${data.destination_id}"
          network_charge: ${data.charge},
          vehicle_option: "EXPRESSBUS",
          vehicle_type: "${data.vehicle_type}"
        }
      ) {
        returning {
          id
        }
      }
    }
  `);
};

/* 오토바이 다마스 라보 요금 조회 */
export const threeSingleCorpCharge = async (expected: {
  departure_id: string;
  destination_id: string;
}) =>
  await graphqlQuery(`{
    charge_algoquickcharge(
      where: {
        departure_id: {_eq: "${expected.departure_id}"},
        destination_id: {_eq: "${expected.destination_id}"}
      }) {
        motorcycle
        damas
        labo
      }
    }
  `)
    .then((res) => res.data.data.charge_algoquickcharge[0])
    .catch((err) => console.log(err));

/* 트럭 요금 조회 */
export const truckSingleCorpCharge = async (expected: {
  departure_id: string;
  destination_id: string;
}) =>
  await graphqlQuery(`{
    charge_algoquickcharge(
      where: {
        departure_id: {_eq: "${expected.departure_id}"},
        destination_id: {_eq: "${expected.destination_id}"}
      }) {
        truck_1t
        truck_1_4t
        truck_2_5t
        truck_3_5t
        truck_5t
        truck_8t
        truck_11t
        truck_14t
        truck_15t
        truck_18t
        truck_25t
      }
    }
  `)
    .then((res) => res.data.data.charge_algoquickcharge[0])
    .catch((err) => console.log(err));

/* 트럭 1~5톤까지 */
export const truckWeightFiveLower = (truckCharge: number) =>
  graphqlQuery(`{
    algoquick_truckoptioncharge(where: {truck_charge: {_eq: ${truckCharge}}}) {
      truck_charge
      canvas_charge
      box_charge
      wing_charge
      chiller_box_charge
      chiller_wing_charge
      freezer_box_charge
      freezer_wing_charge
    }
  }`)
    .then((res) => res.data.data.algoquick_truckoptioncharge[0])
    .catch((err) => console.log(err));

/* 트럭 5톤 초과 */
export const truckWeightFiveUpper = (truckCharge: number) =>
  graphqlQuery(`{
    algoquick_truckoptionchargeover(where: {truck_charge: {_eq: ${truckCharge}}}) {
      truck_charge
      canvas_charge
      box_charge
      wing_charge
      chiller_box_charge
      chiller_wing_charge
      freezer_box_charge
      freezer_wing_charge
    }
  }`)
    .then((res) => res.data.data.algoquick_truckoptionchargeover[0])
    .catch((err) => console.log(err));

export const networkSingleCorpCharge = async (expected: {
  departure_id: string;
  destination_id: string;
}) => {
  return graphqlQuery(`{
    charge_networkcharge(
      where: {
        departure_id: {_eq: "${expected.departure_id}"},
        destination_id: {_eq: "${expected.destination_id}"}
      }) {
        motorcycle
        damas
      }
    }`)
    .then((res) => res.data.data.charge_networkcharge[0])
    .catch((err) => console.log(err));
};

export const deleteSingleCorpCharge = async (id: number) =>
  await graphqlQuery(`
    mutation {
      delete_corporations_corporationcharge(where: {id: {_eq: ${id}}}) {
        returning {
          id
        }
      }
    }
  `);

export const deleteNetworkSingleCorpCharge = async (id: number) =>
  graphqlQuery(`
    mutation {
      delete_corporations_corporationnetworkcharge(where: {id: {_eq: ${id}}}) {
        returning {
          id
        }
      }
    }
  `);

export const updateSingleCorpCharge = async (data: {
  id: number;
  charge: number;
  modified: string;
}) =>
  await graphqlQuery(`
    mutation {
      update_corporations_corporationcharge(
        where: {
          id: {_eq: "${data.id}"}}, 
            _set: {
              charge: "${data.charge}",
              modified: "${data.modified}"
            }) {
        returning {
          id
        }
      }
    }
  `);

export const updateNetworkSingleCorpCharge = async (data: {
  id: number;
  charge: number;
  modified: string;
}) =>
  graphqlQuery(`
    mutation {
      update_corporations_corporationnetworkcharge(
        where: {
          id: {_eq: "${data.id}"}
        },
        _set: {
          network_charge: ${data.charge},
          modified: "${data.modified}"
        }) {
        returning {
          id
        }
      }
    }`);

/* Corp CRUD */
export const createCorp = async (data: CorpProps) => {
  const timeCheck = new Date().toISOString();
  const uuid = uuidV4();
  return await graphqlQuery(`
    mutation {
      insert_corporations_corporation(
        objects: {
          id: "${uuid}",
          created: "${timeCheck}",
          modified: "${timeCheck}",
          name: "${data.name}",
          channel: "${data.channel}",
          phone: "${data.phone}",
          address: "${data.address}",
          registration_number: "${data.registration_number}",
          contracted: "${data.contracted}",
          limit_budget: ${data.limit_budget},
          fixed_date: ${data.fixed_date},
          discount_rate: ${data.discount_rate},
          budget_enable: ${data.budget_enable},
          restrict_by_budget: ${data.restrict_by_budget},
          order_approval_amt: ${data.order_approval_amt},
          order_approval_enable: ${data.order_approval_enable},
          order_approval_user_select_enable: ${
            data.order_approval_user_select_enable
          },
          order_approval_time_enable: ${data.order_approval_time_enable},
          order_approval_time: {
            time: {
              end: "${data.order_approval_time_end}",
              start: "${data.order_approval_time_start}"
            },
            holiday: ${data.order_approval_time_holiday},
            weekend: ${data.order_approval_time_weekend}
          },
          order_client_fix_enable: ${data.order_client_fix_enable},
          billing_department_enable: ${data.billing_department_enable},
          costcenter_change_memo_enable: ${data.costcenter_change_memo_enable},
          corporate_account_enable: ${data.corporate_account_enable},
          corporate_order_memo_enable: ${data.corporate_order_memo_enable},
          corporate_card_memo_enable: ${data.corporate_card_memo_enable},
          express_order_memo_enable: ${data.express_order_memo_enable},
          corporate_credit_enable: ${data.corporate_credit_enable},
          corporate_department_enable: ${data.corporate_department_enable},
          corporate_department_select_enable: ${
            data.corporate_department_select_enable
          },
          corporate_department_select_type: "${
            data.corporate_department_select_type
          }",
          corporate_email_address: "${data.corporate_email_address}"
          corporate_email_address_list: "{${
            data.corporate_email_address_list
          }}",
          corporate_credit: ${data.corporate_credit},
          excel_column_option: {
            ${EXCEL_COLUMNS.reduce(
              (acc, opt) => `${acc}
            ${opt.key}: ${opt.default}`,
              ""
            )}
          }
        }) {
        returning {
          id
        }
      }
      insert_corporations_motorcyclebulkscore(
        objects: {
          shopping_bag: 1.5,
          big_shopping_bag: 2, 
          small_box: 1.5, 
          medium_box: 2.5, 
          big_box: 3, 
          oversize_big_box: 5, 
          small_sack: 2, 
          big_sack: 5, 
          corporation_id: "${uuid}", 
          created: "${timeCheck}", 
          modified: "${timeCheck}"
        }) {
        returning {
          corporation_id
        }
      }
      insert_orders_credit(
        objects: {
          corporation_id: "${uuid}",
          credit_type: "CORPORATE",
          query_type: "CREATE",
          credit_amt: ${data.corporate_credit},
          current_credit: ${data.corporate_credit},
          created: "${timeCheck}", 
          modified: "${timeCheck}"
        }) {
        returning {
          corporation_id
        }
      }
    }
  `);
};
export const getCorp = (id: string) =>
  graphqlQuery(`{
    corporations_corporation_by_pk(id: "${id}") {
      id
      name
      channel
      phone 
      address
      registration_number
      contracted
      budget_enable
      limit_budget
      restrict_by_budget
      fixed_date
      discount_rate
      order_approval_amt
      order_approval_enable
      order_approval_user_select_enable
      order_approval_time_enable
      order_approval_time
      order_client_fix_enable
      billing_department_enable
      costcenter_change_memo_enable
      corporate_account_enable
      corporate_order_memo_enable
      corporate_card_memo_enable
      express_order_memo_enable
      corporate_credit_enable
      corporate_department_enable
      corporate_department_select_enable
      corporate_department_select_type
      corporate_email_address
      corporate_email_address_list
      corporate_credit
      excel_column_option
      users_user {
        id
      }
      logo
    }
    users_user(
      where: {corporations_corporation: {id: {_eq: "${id}"}}},
      order_by: {username: asc}
    ) {
      id
      isd_c_code
      username
      fullname
      phone
      email
      employee_number
      platform
      corporations_corporation {
          name
      }
      corporations_department {
          name
      }
    }
    corporations_department(
      where: {corporation_id: {_eq: "${id}"}},
      order_by: {name: asc}
    ) {
      id
      name
      limit_budget
      department_credit
      department_credit_enable
      corporations_corporation {
        name
      }
    }
  }
`);
export const updateCorp = (
  data: CorpProps & { prev_corporate_credit: number },
  options: ExcelColumnOption[]
) => {
  let administrator_id;
  if (data.administrator_id?.trim() !== "") {
    administrator_id = `"${data.administrator_id}"`;
  } else {
    administrator_id = null;
  }

  const now = new Date();
  let query = `
  update_corporations_corporation(
    where: {
      id: {_eq: "${data.id}"}
    },
    _set: {
      id: "${data.id}",
      name: "${data.name}",
      channel: "${data.channel}",
      phone: "${data.phone}",
      address: "${data.address}",
      registration_number: "${data.registration_number}",
      contracted: "${data.contracted}",
      budget_enable: ${data.budget_enable},
      limit_budget: ${data.limit_budget},
      restrict_by_budget: ${data.restrict_by_budget},
      fixed_date: ${data.fixed_date},
      discount_rate: ${data.discount_rate},
      administrator_id: ${administrator_id},
      order_approval_amt: ${data.order_approval_amt},
      order_approval_enable: ${data.order_approval_enable},
      order_approval_user_select_enable: ${
        data.order_approval_user_select_enable
      },
      order_approval_time_enable: ${data.order_approval_time_enable},
      order_approval_time: {
        time: {
          end: "${data.order_approval_time_end}",
          start: "${data.order_approval_time_start}"
        },
        holiday: ${data.order_approval_time_holiday},
        weekend: ${data.order_approval_time_weekend}
      },
      order_client_fix_enable: ${data.order_client_fix_enable},
      billing_department_enable: ${data.billing_department_enable},
      costcenter_change_memo_enable: ${data.costcenter_change_memo_enable},
      corporate_account_enable: ${data.corporate_account_enable},
      corporate_order_memo_enable: ${data.corporate_order_memo_enable},
      corporate_card_memo_enable: ${data.corporate_card_memo_enable},
      express_order_memo_enable: ${data.express_order_memo_enable},
      corporate_credit_enable: ${data.corporate_credit_enable},
      corporate_department_enable:${data.corporate_department_enable},
      corporate_department_select_enable: ${
        data.corporate_department_select_enable
      },
      corporate_department_select_type: "${
        data.corporate_department_select_type
      }",
      corporate_email_address: "${data.corporate_email_address}"
      corporate_email_address_list: "{${data.corporate_email_address_list}}",
      corporate_credit: ${data.corporate_credit},
      excel_column_option: {${options.reduce(
        (acc, opt) => `${acc}
        ${opt.key}: ${opt.value}`,
        ""
      )}}
    }) {
    returning {
      id
    }
  }`;
  if (data.prev_corporate_credit !== data.corporate_credit)
    query += `
  insert_orders_credit(
    objects: {
      corporation_id: "${data.id}",
      credit_type: "CORPORATE",
      query_type: "${
        data.prev_corporate_credit < data.corporate_credit
          ? "UPDATE_PLUS"
          : "UPDATE_MINUS"
      }",
      credit_amt: ${Math.abs(
        data.prev_corporate_credit - data.corporate_credit
      )},
      current_credit: ${data.corporate_credit},
      memo: "이전 법인크레딧:${data.prev_corporate_credit}",
      created: "${now.toISOString()}", 
      modified: "${now.toISOString()}"
    }) {
    returning {
      corporation_id
    }
  }`;
  return graphqlQuery(`mutation {${query}
}`);
};
export const beforeDeleteCorp = async (id: string) =>
  await graphqlQuery(`
  mutation {
    delete_corporations_motorcyclebulkscore(where: {corporation_id: {_eq: "${id}"}}) {
      returning {
        corporation_id
      }
    }
    delete_orders_credit(where: {corporation_id: {_eq: "${id}"}}) {
      returning {
        corporation_id
      }
    }
    delete_corporations_taxbill(where: {corporation_id: {_eq: "${id}"}}) {
      returning {
        corporation_id
      }
    }
    delete_sso_partneruser(where: {corporation_id: {_eq: "${id}"}}) {
      returning {
        corporation_id
      }
    }
  }
  `);
export const deleteCorp = async (id: string) =>
  await graphqlQuery(`
  mutation {
    delete_corporations_corporation(where: {id: {_eq: "${id}"}}) {
      returning {
        id
      }
    }
  }`);

export const getCorpBulk = (id: string) =>
  graphqlQuery(`{
    corporations_motorcyclebulkscore_by_pk(corporation_id: "${id}") {
      corporation_id
      shopping_bag
      big_shopping_bag
      small_box
      medium_box
      big_box
      oversize_big_box
      small_sack
      big_sack
      corporations_corporation {
        name
      }
    }
  }
`);

export const updateCorpBulk = (data: BulkType) =>
  graphqlQuery(`
    mutation {
      update_corporations_motorcyclebulkscore(
        where: {
          corporation_id: {_eq: "${data.corporation_id}"}},
          _set: {
            shopping_bag: "${data.shopping_bag}"
            big_shopping_bag: "${data.big_shopping_bag}"
            small_box: "${data.small_box}"
            medium_box: "${data.medium_box}"
            big_box: "${data.big_box}"
            oversize_big_box: "${data.oversize_big_box}"
            small_sack: "${data.small_sack}"
            big_sack: "${data.big_sack}"
            }) {
        returning {
          corporation_id
        }
      }
    }    
`);
