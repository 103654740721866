import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
} from "@material-ui/core";

import EnhancedTableHead from "../dataTable/EnhancedTableHead";
import { CorporateDept, headRows } from "../../containers/departments/types";
import { stableSort, getSorting } from "../dataTable/base";

import { CustomLegend } from "../common/Base";
import Paper from "../common/Paper";

interface DepartmentsListProps {
  rows: CorporateDept[];
}

function DepartmentsList({ rows }: DepartmentsListProps) {
  const history = useHistory();
  const classes = useStyles();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const handleRequestSort = (e: React.MouseEvent, property: string) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleRowClick = (id: number) => history.push(`/department/${id}`);

  return (
    <CustomPaper>
      <CustomLegend>부서 관리 ({rows.length})</CustomLegend>
      <Table>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headRows={headRows}
        />
        <TableBody>
          {stableSort(rows, getSorting(order, orderBy)).map(
            (row: CorporateDept) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row.id}
                  onClick={() => handleRowClick(row.id)}
                >
                  <TableCell>{row.corporations_corporation.name}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.limit_budget}</TableCell>
                  <TableCell className={classes.root}>
                    {row.department_credit_enable ? "O" : "X"}
                  </TableCell>
                  <TableCell>{row.department_credit}</TableCell>
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </CustomPaper>
  );
}
export default DepartmentsList;

const CustomPaper = styled(Paper)`
  position: relative;
  padding: 1.5rem;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
`;

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: "4.5rem",
  },
}));
