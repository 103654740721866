import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";

import UserAuthDetailTable from "./UserAuthDetailTable";
import UserAuthAddTable from "./UserAuthAddTable";

import {
  AddAuthDataType,
  filteredMenu,
  AdminUserAuthsType,
} from "../../../containers/users/types";
import { RowDivider } from "../../common/Base";
import { ModalWrapper } from "../../common/Modal";

interface UsersAuthDetailProps {
  authInfo: AdminUserAuthsType[];
  modalRef: React.ForwardedRef<HTMLDivElement>;
  outSideAuthModal: (e: React.MouseEvent) => void;
  closeAuthModal: () => void;
  onAuthsChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onAuthsSubmit: (e: React.FormEvent) => void;
  onAuthsRowDelete: (e: AdminUserAuthsType) => void;
  onAuthsRowCreate: (e: AddAuthDataType) => void;
}

function UsersAuthDetailModal({
  authInfo,
  modalRef,
  outSideAuthModal,
  closeAuthModal,
  onAuthsChange,
  onAuthsSubmit,
  onAuthsRowDelete,
  onAuthsRowCreate,
}: UsersAuthDetailProps) {
  const [authOptions, setAuthOptions] = useState<AdminUserAuthsType[]>([]);

  const fetchData = () => {
    if (authInfo.length === 0) return;

    const filteredData = authInfo.map((el) => {
      return filteredMenu.reduce((acc: any, { menuId, label, letter }) => {
        if (el.menu_id === menuId) {
          acc = { ...el, ...{ label, letter } };
        }
        return acc;
      }, {});
    });
    setAuthOptions(filteredData);
  };

  // computed
  useEffect(() => {
    fetchData();
  }, [authInfo]);

  return (
    <ModalWrapper ref={modalRef} onClick={(e) => outSideAuthModal(e)}>
      <DetailModal>
        <UserAuthDetailTable
          authOptions={authOptions}
          onAuthsChange={onAuthsChange}
          onAuthsRowDelete={onAuthsRowDelete}
        />
        <UserAuthAddTable
          authInfo={authInfo}
          onAuthsRowCreate={onAuthsRowCreate}
        />
        <RowDivider size="2" />
        <ButtonWrapper>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            onClick={closeAuthModal}
          >
            취소
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={onAuthsSubmit}
          >
            저장
          </Button>
        </ButtonWrapper>
      </DetailModal>
    </ModalWrapper>
  );
}

export default UsersAuthDetailModal;

const DetailModal = styled.section`
  padding: 15px 2rem;
  width: 1300px;
  height: 60rem;
  max-height: 95%;
  background-color: #fff;
  box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.15);
  overflow-y: auto;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 30%;
`;
