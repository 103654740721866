import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  Fragment,
} from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  TextField,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  RadioGroup,
  Radio,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import Chip from "@mui/material/Chip";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { LocalizationProvider, DesktopTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DateFnsUtils from "@date-io/date-fns";
import ko from "date-fns/locale/ko";

import ManageCorporateUpload from "./ManageCorporateUpload";
import ManageCorporateStuff from "./ManageCorporateStuff";

import {
  CorpProps,
  ExcelColumnOption,
  ChangeColumnOptionHandler,
  radioInputs,
} from "../../containers/corporation/types";
import { CorporateDept } from "../../containers/departments/types";
import { UsersListType } from "../../containers/users/types";

import {
  ColumnDivider,
  RowDivider,
  FlexBox,
  FlexCenterSpaceBetween,
  FlexColumnCenter,
  CustomLegend,
  CustomTextField,
} from "../common/Base";
import {
  CORPORATAE_DATA_LABEL,
  ChannelData,
} from "../../lib/constants/constants";
import Detail from "../common/Detail";

interface CorporationDetailProps {
  corp: CorpProps;
  users?: UsersListType[];
  depts?: CorporateDept[];
  columnOptions?: ExcelColumnOption[];
  handleChangeColumnOption?: ChangeColumnOptionHandler;
  onChange: (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
      checked?: boolean;
    }>
  ) => void;
  onChangeLowerSync: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeUpperSync: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: React.FormEvent) => void;
  handleDateChange: (name: string, date: Date | null) => void;
  handleDeleteCorp?: () => void;
  action: "add" | "detail";
  chipData: string[];
  setChipData: React.Dispatch<React.SetStateAction<string[]>>;
  handleKeyDown: any;
  handleDelete: (chipToDelete: string) => () => void;
}

const tooltipTitle = (
  <Fragment>
    <b>{"회원명이 의뢰인에 고정되어 사용됩니다."}</b>
    <ul>
      <li>
        {"의뢰인명을 수정할 수 없으며, 의뢰인 목록 기능이 비활성화 됩니다."}
      </li>
      <li>{"재주문시 주문내역의 의뢰인 이름을 불러오지 않습니다."}</li>
    </ul>
  </Fragment>
);

const parseToDate = (time: string) => {
  const [h, m] = time.split(":").map(Number);

  const date = new Date();
  date.setHours(h, m, 0, 0);
  return date;
};

function CorporationDetail({
  corp,
  users,
  depts,
  columnOptions = [],
  handleChangeColumnOption,
  onChange,
  onChangeLowerSync,
  onChangeUpperSync,
  handleDateChange,
  onSubmit,
  handleDeleteCorp,
  action,
  chipData,
  setChipData,
  handleKeyDown,
  handleDelete,
}: CorporationDetailProps) {
  const history = useHistory();
  const user = useSelector(({ user }: RootStateOrAny) => user.user);

  const [departOpen, setDepartOpen] = useState(false); // 부서 버튼
  const [userOpen, setUserOpen] = useState(false); // 회원 버튼
  const [columnOpen, setColumnOpen] = useState(false); // 주문 내역 버튼
  const [projectOpen, setProjectOpen] = useState(false); // 프로젝트 버튼
  const [modalOpen, setModalOpen] = useState(false); // 삭제 확인 modal

  const isDisableChannel = useMemo(() => user?.channel !== "algoquick", [user]);

  useEffect(() => {
    setChipData(corp?.corporate_email_address_list || []);
  }, [corp]);

  const totalCredit = useMemo(() => {
    const calDepartCredit =
      depts?.reduce((acc, cur) => acc + cur.department_credit, 0) || 0;
    return Number(corp.corporate_credit) + calDepartCredit;
  }, [corp.corporate_credit]);

  const handleManageStuffOpen = useCallback(
    (dept: boolean, user: boolean, column: boolean, project: boolean) => () => {
      setDepartOpen(dept);
      setUserOpen(user);
      setColumnOpen(column);
      setProjectOpen(project);
    },
    []
  );

  return (
    <FlexColumnCenter>
      <Detail>
        {action === "detail" && (
          <ButtonArea>
            <ContainedPrimaryButton
              variant="contained"
              color="primary"
              size="large"
              onClick={handleManageStuffOpen(!departOpen, false, false, false)}
            >
              부서 관리
            </ContainedPrimaryButton>
            <ContainedPrimaryButton
              variant="contained"
              color="primary"
              size="large"
              onClick={handleManageStuffOpen(false, !userOpen, false, false)}
            >
              회원 관리
            </ContainedPrimaryButton>
            <ContainedPrimaryButton
              variant="contained"
              color="primary"
              size="large"
              onClick={handleManageStuffOpen(false, false, !columnOpen, false)}
            >
              주문내역 관리
            </ContainedPrimaryButton>
            <ContainedPrimaryButton
              variant="contained"
              color="primary"
              size="large"
              onClick={handleManageStuffOpen(false, false, false, !projectOpen)}
            >
              프로젝트 관리
            </ContainedPrimaryButton>
            <ContainedPrimaryButton
              variant="contained"
              color="primary"
              size="large"
              onClick={() =>
                history.push(`/credit-history`, {
                  corporationId: corp.id,
                  corporationName: corp.name,
                })
              }
            >
              크레딧 내역
            </ContainedPrimaryButton>
            <ContainedPrimaryButton
              variant="contained"
              color="primary"
              size="large"
              onClick={() => history.push(`/corporation/${corp.id}/bulk`)}
            >
              과적 포인트
            </ContainedPrimaryButton>
            <ContainedPrimaryButton
              variant="contained"
              color="primary"
              size="large"
              onClick={() => history.push(`/corporation/${corp.id}/extra`)}
            >
              추가 요금
            </ContainedPrimaryButton>
          </ButtonArea>
        )}
        <ManageCorporateUpload
          corp={corp}
          deptOpened={departOpen}
          userOpened={userOpen}
          projectOpened={projectOpen}
        />
        <ManageCorporateStuff
          deptOpened={departOpen}
          departments={depts || []}
          userOpened={userOpen}
          users={users || []}
          columnOpened={columnOpen}
          columnOptions={columnOptions}
          projectOpened={projectOpen}
          handleChangeColumnOption={
            handleChangeColumnOption as ChangeColumnOptionHandler
          }
        />
        {corp.id && (
          <TextField
            label={CORPORATAE_DATA_LABEL.id}
            id="id"
            name="id"
            value={corp.id}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
        )}
        <CustomFlexWapper>
          <CustomTextField
            label={`${CORPORATAE_DATA_LABEL.name}*`}
            id="name"
            name="name"
            value={corp.name}
            variant="filled"
            margin="normal"
            width="60%"
            onChange={onChange}
          />
          <CustomFormControl variant="filled">
            <InputLabel id="filled-corporate-channel">
              {CORPORATAE_DATA_LABEL.channel}
            </InputLabel>
            <Select
              labelId="filled-corporate-channel"
              id="filled-corporate-channel"
              name="channel"
              value={corp.channel}
              onChange={onChange}
              disabled={isDisableChannel}
            >
              {ChannelData.map((option) => (
                <MenuItem key={option.key} value={option.key}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </CustomFormControl>
        </CustomFlexWapper>
        <TextField
          label={`${CORPORATAE_DATA_LABEL.corporate_email_address_list} 등록 (예 algolab.kr)`}
          placeholder="ex) algolab.kr"
          id="corporate_email_address_list"
          name="corporate_email_address_list"
          variant="filled"
          margin="normal"
          fullWidth
          onKeyDown={handleKeyDown}
          onChange={onChange}
          InputProps={{
            startAdornment: chipData.map((item: string) => (
              <ChipArea key={item}>
                <Chip
                  label={item}
                  tabIndex={-1}
                  onDelete={handleDelete(item)}
                />
              </ChipArea>
            )),
          }}
        />
        <TextField
          label={CORPORATAE_DATA_LABEL.phone}
          id="phone"
          name="phone"
          value={corp.phone}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={onChange}
        />
        <TextField
          label={CORPORATAE_DATA_LABEL.address}
          id="address"
          name="address"
          value={corp.address}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={onChange}
        />
        <TextField
          label={CORPORATAE_DATA_LABEL.registration_number}
          id="registration_number"
          name="registration_number"
          placeholder="하이픈(-)없이 숫자만 입력해주세요"
          value={corp.registration_number}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={onChange}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ko}>
          <DatePicker
            label={`${CORPORATAE_DATA_LABEL.contracted}*`}
            margin="normal"
            id="contracted"
            name="contracted"
            format="yyyy-MM-dd"
            value={corp.contracted}
            onChange={(date) => handleDateChange("contracted", date)}
          />
        </MuiPickersUtilsProvider>
        <RowDivider />
        <FlexBox>
          <FormControlLabel
            control={
              <Switch
                checked={corp.corporate_account_enable}
                onChange={onChange}
              />
            }
            id="corporate_account_enable"
            name="corporate_account_enable"
            label={CORPORATAE_DATA_LABEL.corporate_account_enable}
            labelPlacement="start"
          />
          <ColumnDivider size="2" />
          <FormControlLabel
            control={
              <Switch
                checked={corp.budget_enable}
                onChange={onChangeLowerSync}
              />
            }
            id="budget_enable"
            name="budget_enable"
            label={CORPORATAE_DATA_LABEL.budget_enable}
            labelPlacement="start"
          />
          <ColumnDivider size="2" />
          <FormControlLabel
            control={
              <Switch
                checked={corp.restrict_by_budget}
                onChange={onChangeUpperSync}
              />
            }
            id="restrict_by_budget"
            name="restrict_by_budget"
            label={CORPORATAE_DATA_LABEL.restrict_by_budget}
            labelPlacement="start"
          />
          <ColumnDivider size="2" />
          <FormControlLabel
            control={
              <Switch
                checked={corp.corporate_order_memo_enable}
                onChange={onChange}
              />
            }
            id="corporate_order_memo_enable"
            name="corporate_order_memo_enable"
            label={CORPORATAE_DATA_LABEL.corporate_order_memo_enable}
            labelPlacement="start"
          />
          <ColumnDivider size="2" />
          <FormControlLabel
            control={
              <Switch
                checked={corp.corporate_card_memo_enable}
                onChange={onChange}
              />
            }
            id="corporate_card_memo_enable"
            name="corporate_card_memo_enable"
            label={CORPORATAE_DATA_LABEL.corporate_card_memo_enable}
            labelPlacement="start"
          />
          <ColumnDivider size="2" />
          <FormControlLabel
            control={
              <Switch
                checked={corp.express_order_memo_enable}
                onChange={onChange}
              />
            }
            id="express_order_memo_enable"
            name="express_order_memo_enable"
            label={CORPORATAE_DATA_LABEL.express_order_memo_enable}
            labelPlacement="start"
          />
          <ColumnDivider size="2" />
          <FormControlLabel
            control={
              <Switch
                checked={corp.corporate_department_enable}
                onChange={onChange}
              />
            }
            id="corporate_department_enable"
            name="corporate_department_enable"
            label={CORPORATAE_DATA_LABEL.corporate_department_enable}
            labelPlacement="start"
          />
        </FlexBox>
        <RowDivider size="2" />
        <FlexBox>
          <FormControlLabel
            control={
              <Switch
                checked={corp.corporate_credit_enable}
                onChange={onChange}
              />
            }
            id="corporate_credit_enable"
            name="corporate_credit_enable"
            label={CORPORATAE_DATA_LABEL.corporate_credit_enable}
            labelPlacement="start"
          />
          <ColumnDivider size="2" />
          <FormControlLabel
            control={
              <Switch
                checked={corp.billing_department_enable}
                onChange={onChange}
              />
            }
            id="billing_department_enable"
            name="billing_department_enable"
            label={CORPORATAE_DATA_LABEL.billing_department_enable}
            labelPlacement="start"
          />
          <ColumnDivider size="2" />
          <FormControlLabel
            control={
              <Switch
                checked={corp.costcenter_change_memo_enable}
                onChange={onChange}
              />
            }
            id="costcenter_change_memo_enable"
            name="costcenter_change_memo_enable"
            label={CORPORATAE_DATA_LABEL.costcenter_change_memo_enable}
            labelPlacement="start"
          />
          <ColumnDivider size="2" />
          <HtmlTooltip title={tooltipTitle}>
            <FormControlLabel
              control={
                <Switch
                  checked={corp.order_client_fix_enable}
                  onChange={onChange}
                />
              }
              id="order_client_fix_enable"
              name="order_client_fix_enable"
              label={CORPORATAE_DATA_LABEL.order_client_fix_enable}
              labelPlacement="start"
            />
          </HtmlTooltip>
        </FlexBox>
        <RowDivider size="2" />
        <CustomFlexWapper>
          <CustomFlexBox>
            <CustomLegend top="-7" font="13">
              주문승인 사용여부
            </CustomLegend>
            <FormControlLabel
              control={
                <Switch
                  checked={corp.order_approval_enable}
                  onChange={onChangeLowerSync}
                />
              }
              id="order_approval_enable"
              name="order_approval_enable"
              label={CORPORATAE_DATA_LABEL.order_approval_enable}
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={corp.order_approval_user_select_enable}
                  onChange={onChangeUpperSync}
                />
              }
              id="order_approval_user_select_enable"
              name="order_approval_user_select_enable"
              label={CORPORATAE_DATA_LABEL.order_approval_user_select_enable}
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={corp.order_approval_time_enable}
                  onChange={(e) => {
                    onChangeUpperSync(e);
                    onChangeLowerSync(e);
                  }}
                />
              }
              id="order_approval_time_enable"
              name="order_approval_time_enable"
              label={CORPORATAE_DATA_LABEL.order_approval_time_enable}
              labelPlacement="start"
            />
          </CustomFlexBox>
          <CustomTextField
            label={CORPORATAE_DATA_LABEL.order_approval_amt}
            type="number"
            id="order_approval_amt"
            name="order_approval_amt"
            value={corp.order_approval_amt}
            inputProps={{ min: 0, step: 1000 }}
            variant="filled"
            onChange={onChange}
            disabled={!corp.order_approval_enable}
          />
        </CustomFlexWapper>
        <RowDivider size="1" />
        <CustomFlexBox width="100%">
          <CustomLegend top="-7" font="13">
            주문승인 적용시간
          </CustomLegend>
          <FormControlLabel
            control={
              <Switch
                checked={corp.order_approval_time_holiday}
                onChange={onChangeUpperSync}
              />
            }
            id="order_approval_time_holiday"
            name="order_approval_time_holiday"
            label="공휴일"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Switch
                checked={corp.order_approval_time_weekend}
                onChange={onChangeUpperSync}
              />
            }
            id="order_approval_time_weekend"
            name="order_approval_time_weekend"
            label="주말"
            labelPlacement="start"
          />
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ko}>
            <DesktopTimePicker
              label={CORPORATAE_DATA_LABEL.order_approval_time_start}
              ampm={false}
              slotProps={{
                textField: { variant: "standard", margin: "none" },
              }}
              sx={{ marginLeft: "24px" }}
              value={parseToDate(corp.order_approval_time_start)}
              disabled={!corp.order_approval_time_enable}
              onChange={(date) => {
                handleDateChange("order_approval_time_start", date);
              }}
            />
            <DesktopTimePicker
              label={CORPORATAE_DATA_LABEL.order_approval_time_end}
              ampm={false}
              slotProps={{
                textField: { variant: "standard", margin: "none" },
              }}
              sx={{ marginLeft: "24px" }}
              value={parseToDate(corp.order_approval_time_end)}
              disabled={!corp.order_approval_time_enable}
              onChange={(date) => {
                handleDateChange("order_approval_time_end", date);
              }}
            />
          </LocalizationProvider>
        </CustomFlexBox>
        <RowDivider size="2" />
        <CustomFlexWapper>
          <CustomFlexBox>
            <CustomLegend top="-7" font="13">
              법인 회원가입
            </CustomLegend>
            <FormControlLabel
              control={
                <Switch
                  checked={corp.corporate_department_select_enable}
                  onChange={onChange}
                />
              }
              id="corporate_department_select_enable"
              name="corporate_department_select_enable"
              label={CORPORATAE_DATA_LABEL.corporate_department_select_enable}
              labelPlacement="start"
            />
          </CustomFlexBox>
          <CustomFlexBox>
            <CustomLegend top="-7" font="13">
              부서등록 기능 타입
            </CustomLegend>
            <FormControl style={{ paddingLeft: "1.5rem" }}>
              <RadioGroup
                row
                name="corporate_department_select_type"
                onChange={onChange}
                value={corp.corporate_department_select_type}
              >
                {radioInputs.map(
                  (el: { value: string; label: string }, idx: number) => {
                    return (
                      <FormControlLabel
                        control={<Radio />}
                        key={idx}
                        value={el.value}
                        label={el.label}
                      />
                    );
                  }
                )}
              </RadioGroup>
            </FormControl>
          </CustomFlexBox>
        </CustomFlexWapper>
        <RowDivider />
        <TextField
          label={`${CORPORATAE_DATA_LABEL.limit_budget}*`}
          type="number"
          id="limit_budget"
          name="limit_budget"
          value={corp.limit_budget}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={onChange}
        />
        <TextField
          label={`${CORPORATAE_DATA_LABEL.fixed_date}*`}
          type="number"
          id="fixed_date"
          name="fixed_date"
          value={corp.fixed_date}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={onChange}
        />
        <TextField
          label={`${CORPORATAE_DATA_LABEL.discount_rate}(%)*`}
          type="number"
          id="discount_rate"
          name="discount_rate"
          value={corp.discount_rate}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={onChange}
        />
        <FlexBox>
          <TextField
            label={`${CORPORATAE_DATA_LABEL.corporate_credit}*`}
            type="number"
            id="corporate_credit"
            name="corporate_credit"
            value={corp.corporate_credit}
            inputProps={{ min: 0, step: 1000 }}
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={onChange}
          />
          {corp.id && (
            <>
              <ColumnDivider size="2" />
              <TextField
                label={`${corp.name} 법인 전체 크레딧 (법인 + 부서)`}
                type="number"
                id="corporate_credit"
                name="corporate_credit"
                value={totalCredit}
                disabled
                variant="filled"
                margin="normal"
                fullWidth
              />
            </>
          )}
        </FlexBox>
        <RowDivider />
        {users && users.length > 0 && (
          <FormControl variant="outlined" fullWidth>
            <InputLabel>{CORPORATAE_DATA_LABEL.administrator_id}</InputLabel>
            <Select
              value={corp?.administrator_id || " "}
              onChange={onChange}
              input={
                <OutlinedInput name="administrator_id" id="administrator_id" />
              }
            >
              <MenuItem value=" ">
                <em>None</em>
              </MenuItem>
              {users.map((option: UsersListType) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.username}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <RowDivider />
        <FlexCenterSpaceBetween>
          {corp.id ? (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setModalOpen(true)}
            >
              삭제
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => history.goBack()}
            >
              취소
            </Button>
          )}
          <Button variant="outlined" color="primary" onClick={onSubmit}>
            저장
          </Button>
          <Dialog
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{`${corp.name}을 삭제 하시겠습니까?`}</DialogTitle>
            <DialogActions>
              <Button onClick={() => setModalOpen(false)} color="primary">
                취소
              </Button>
              <Button onClick={handleDeleteCorp} color="primary" autoFocus>
                삭제
              </Button>
            </DialogActions>
          </Dialog>
        </FlexCenterSpaceBetween>
      </Detail>
    </FlexColumnCenter>
  );
}
export default CorporationDetail;

const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const ContainedPrimaryButton = styled(Button)`
  margin: 0 2rem 1rem 0 !important;
  border-radius: 20px !important;
  background-color: RGB(63, 81, 181, 0.8) !important;
`;

const CustomFlexWapper = styled(FlexBox)`
  gap: 1rem;
`;

const CustomFlexBox = styled(FlexBox)<{ width?: string }>`
  position: relative;
  padding: 8px 0 6px;
  width: ${({ width }) => width || "50%"};
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
`;

const ChipArea = styled.div`
  margin: 1.5rem 5px 5px 0;
`;

const CustomFormControl = styled(FormControl)`
  width: 40%;
  margin: 16px 0 8px 0 !important;
`;

const HtmlTooltip = withStyles({
  tooltip: {
    padding: "10px",
    maxWidth: 500,
    fontSize: "1rem",
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: "#fff",
    border: "1px solid #dadde9",
  },
})(Tooltip);
