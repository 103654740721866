export type CorporateDept = {
  corporations_corporation: { name: string };
  department_credit: number;
  department_credit_enable: boolean;
  id: number;
  limit_budget: number;
  name: string;
};

export const headRows = [
  {
    id: "corporation",
    numeric: false,
    disablePadding: false,
    label: "법인명",
  },
  { id: "name", numeric: false, disablePadding: false, label: "부서명" },
  {
    id: "limit_budget",
    numeric: false,
    disablePadding: false,
    label: "예산한도",
  },
  {
    id: "department_credit_enable",
    numeric: false,
    disablePadding: false,
    label: "부서 크레딧 사용여부",
  },
  {
    id: "department_credit",
    numeric: false,
    disablePadding: false,
    label: "부서 크레딧",
  },
];
