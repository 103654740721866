import graphqlQuery from "./base";
import { getUTCTodayDate } from "../datetime";
import {
  AddAuthDataType,
  AdminUserAuthsType,
} from "../../containers/users/types";
import { ChannelMenu } from "../constants/constants";
import { getChannelQuery } from "./orders";

// 회원권한관리
export const getAdminUsers = async () => {
  return await graphqlQuery(`{
    users_adminuser(
      order_by: {auth_level: asc},
      where: {
        _not: {user_id: {_is_null: true}}
      }) {
      auth_level
      id
      ip_address
      is_external_access
      otp_key
      user_id
      users_user {
        corporations_corporation {
          id
          name
        }
        corporations_department {
          id
          name
        }
        fullname
        username
      }
    }
  }`);
};

// 회원권한관리 - usersAuthDetail
export const getAdminUser = async (id: string) => {
  return await graphqlQuery(`{
    users_adminuser_by_pk(id: ${id}) {
      auth_level
      id
      ip_address
      is_external_access
      otp_key
      user_id
      users_user {
        corporations_corporation {
          id
          name
        }
        corporations_department {
          id
          name
        }
        fullname
        username
        users_adminuserauths {
          menu_id
          search
          privacy
          excel
          etc1
          custom1
          id
        }
      }
    }
  }`);
};

// 회원권한관리 - 권한 업데이트
export const updateAdminUser = async (data: {
  id: number;
  auth_level: string;
  is_external_access: boolean;
}) => {
  return graphqlQuery(`
    mutation {
      update_users_adminuser(
        where: {
          id: {_eq: "${data.id}"}},
            _set: {
              auth_level: "${data.auth_level}"
              is_external_access: "${data.is_external_access}"
          }) {
        returning {
          id
        }
      }
    }
  `);
};
export const updateAdminUserAuths = async (
  userId: string,
  { custom1, etc1, excel, menu_id, privacy, search }: AdminUserAuthsType
) => {
  return graphqlQuery(`
    mutation {
      update_users_adminuserauth(
        where: {
          users_user: {
            id: {_eq: "${userId}"}
          },
          menu_id: {
            _eq: "${menu_id}"
          }
        },
        _set: {
          custom1: "${custom1?.trim() || ""}",
          etc1: ${etc1},
          excel: ${excel},
          menu_id: "${menu_id}",
          privacy: ${privacy},
          search: ${search}
        }
      ) {
        returning {
          id
        }
      }
    }
  `);
};

export const createAdmiinUserAuthRow = async (
  userId: string,
  auth: AddAuthDataType
) => {
  const { menu_id, search, privacy, excel, etc1, custom1 } = auth;
  const timeCheck = new Date().toISOString();

  return graphqlQuery(`
    mutation {
      insert_users_adminuserauth(
        objects: {
          created: "${timeCheck}",
          modified: "${timeCheck}",
          user_id: "${userId}",
          menu_id: "${menu_id}",
          search: ${search},
          privacy: ${privacy},
          excel: ${excel},
          etc1: ${etc1},
          custom1: "${custom1}",
          insert: false,
          update: false,
          delete: false,
          save: false
        }) {
        returning {
          id
        }
      }
    }
  `);
};

// 회원권한관리 - 특정권한 삭제
export const deleteAdminUserAuthsRow = async (id: string) =>
  graphqlQuery(`
    mutation {
      delete_users_adminuserauth(where: {id: {_eq: ${id}}}) {
        returning {
          id
        }
      }
    }
`);

// 회원권한관리 - 전체권한 삭제
export const deleteAdminUserAuthsAll = (userId: string) =>
  graphqlQuery(`
    mutation {
      delete_users_adminuser(
        where: {
          user_id: { _eq: "${userId}" }
        }
      ) {
        affected_rows
      }
      delete_users_adminuserauth(
        where: {
          user_id: { _eq: "${userId}" }
        }
      ) {
        affected_rows
      }
    }
  `);

const searchLogQuery = (search: string) => {
  return search.trim() === ""
    ? ``
    : `_or: [
        {content: {_ilike: "%${search}%"}},
        {content_type: {_ilike: "%${search}%"}},
        {ip_address: {_ilike: "%${search}%"}},
        {menu_name: {_ilike: "%${search}%"}},
        {users_user: {
          username: {_ilike: "%${search}%"}}
        }
      ]`;
};

/* 로그 기록 조회 */
export const listLogs = async (search = "") => {
  // default parameter (전달하지 않으면 기본값 설정)
  const todayDate = getUTCTodayDate();

  return await graphqlQuery(`{
    users_adminuserlog(
      order_by: {created: asc},
      where: {
        _and:[
          {_not: {
            created: {_lt: "${todayDate}"}
          }},
          {${searchLogQuery(search)}}
        ]
      }
    ) {
      created
      content
      content_type
      id
      menu_name
      user_id
      users_user {
        username,
        fullname
      }
    }
  }`);
};

/* 과거 로그 기록 조회 */
export const listLogsPast = async (
  start: string,
  end: string,
  search = ""
  // default parameter (전달하지 않으면 기본값 설정)
) => {
  return await graphqlQuery(`{
    users_adminuserlog(
      order_by: {created: asc},
      where: {
        _and:[
          {created: {_gte: "${start}"}},
          {created: {_lte: "${end}"}},
          {${searchLogQuery(search)}}
        ]
      }
    ) {
      created
      content
      content_type
      id
      menu_name
      user_id
      users_user {
        username,
        fullname
      }
    }
  }`);
};

/* 로그 등록 API */
export const postLog = async (
  user_id: string,
  ip_address: string,
  content_type: string,
  content: string,
  menu_name: string | null
) => {
  const timeCheck = new Date().toISOString();
  try {
    await createUserLog({
      user_id: user_id,
      ip_address: ip_address,
      content_type: content_type,
      content: content,
      menu_name: menu_name,
      created: timeCheck,
      modified: timeCheck,
    });
  } catch (error) {
    alert("로그 등록에 실패하였습니다. 개발팀에 문의해주세요.");
    console.log(error);
  }
};
const createUserLog = async (data: {
  user_id: string;
  ip_address: string;
  content_type: string;
  content: string;
  menu_name: string | null;
  created: string;
  modified: string;
}) => {
  return graphqlQuery(`
  mutation {
    insert_users_adminuserlog(
      objects: {
        user_id: "${data.user_id}",
        ip_address: "${data.ip_address}",
        content_type: "${data.content_type}",
        content: "${data.content}",
        menu_name: "${data.menu_name}",
        created: "${data.created}",
        modified: "${data.modified}"
      }) {
      returning {
        id
      }
    }
  }
`);
};

export const getUsersSearch = async (search: string, channel: ChannelMenu) => {
  return graphqlQuery(`{
    users_user(
      where: {
        _and: [
          ${getChannelQuery(channel)},
          {_or: [
            {isd_c_code: {_ilike: "%${search}%"}},
            {username: {_ilike: "%${search}%"}},
            {fullname: {_ilike: "%${search}%"}},
            {phone: {_ilike: "%${search}%"}},
          ]}
        ]
      }) {
          id
          isd_c_code
          username
          fullname
          phone
          platform
          corporations_corporation {
              name
          }
          corporations_department {
              name
          }
    }}
  `);
};

export const getUser = async (id: string) =>
  await graphqlQuery(`{
    users_user_by_pk(id: "${id}") {
      id
      isd_c_code
      channel
      username
      fullname
      phone
      email
      employee_number
      platform
      user_credit
      corporations_corporation {
        id
        name
        channel
      }
      corporations_department {
        id
        name
        administrator_id
      }
    }
}`);

export const getUserByUsername = (username: string) => {
  return graphqlQuery(`{
    users_user(
      where: {
        username: {
          _eq: "${username}"
        }
      }) {
      id
    }}
  `);
};

export async function listCorpUser(id: string) {
  return graphqlQuery(`{
    users_user(
      where: {corporations_corporation: {id: {_eq: "${id}"}}}
    ) {
      id
      username
    }}
  `);
}

export const updateUser = (data: {
  id: string;
  isd_c_code: string;
  fullname: string;
  email: string | null;
  corporation: string;
  department: string | number;
  user_credit: number;
  platform: string[];
}) => {
  const sortedPlatform = data.platform.sort();
  return graphqlQuery(`
    mutation {
      update_users_user_by_pk(
        pk_columns: {id: "${data.id}"},
        _set: {
          isd_c_code: "${data.isd_c_code}",
          fullname: "${data.fullname}",
          email: ${data.email?.trim() ? `"${data.email}"` : null},
          corporation_id: ${
            data.corporation?.trim() ? `"${data.corporation}"` : null
          },
          department_id: ${
            typeof data.department === "number" ? `${data.department}` : null
          },
          user_credit: ${data.user_credit},
          platform: "{${sortedPlatform}}"
        }
      ) {
        id
      }
    }
  `);
};

export const resetAdminAuthority = async (corpId: string, userId: string) => {
  return await graphqlQuery(`
    mutation {
      update_corporations_corporation(
        where: {
          _and: {
            id: {_eq: "${corpId}"},
            administrator_id: {_eq: "${userId}"}
          }
        }, 
        _set: { administrator_id: null }
      ) {
        returning { 
          id 
        }
      }
      update_corporations_department(
        where: {
          _and: {
            corporation_id: {_eq: "${corpId}"},
            administrator_id: {_eq: "${userId}"}
          }
        }
        _set: { administrator_id: null }
      ) {
        returning {
          id
        }
      }
      delete_corporations_admin(
        where: {
          _and: {
            admin_type: {_eq: "BIZ"},
            corporation_id: {_eq: "${corpId}"},
            user_id: {_eq: "${userId}"}
          }
        }
      ) {
        returning {
          id
        }
      }
    }
  `);
};
