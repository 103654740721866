import { Dialog, DialogContent } from "@material-ui/core";
import React from "react";
import { StyledDialogTitle } from "../../components/common/Modal";
import Call24Order from "../../components/order/Call24Order";

type Call24OrderDetailProps = {
  callOrderPopup: boolean;
  order: any;
  riderList: any;
  call24: any;
  handleCallOrderOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCall24Api: () => void;
  setCallOrderPopup: (callOrderPopup: boolean) => void;
};

function Call24OrderDetailContainer({
  callOrderPopup,
  setCallOrderPopup,
  order,
  riderList,
  call24,
  handleCallOrderOnChange,
  handleCall24Api,
}: Call24OrderDetailProps) {
  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setCallOrderPopup(false);
  };

  return (
    <Dialog
      open={callOrderPopup}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
    >
      <StyledDialogTitle id="alert-dialog-title">{`전국24시콜화물 접수`}</StyledDialogTitle>
      <DialogContent>
        <Call24Order
          row={order}
          riderList={riderList}
          call24={call24}
          handleCallOrderOnChange={handleCallOrderOnChange}
          handleCallOrderClose={handleClose}
          handleCall24Api={handleCall24Api}
        />
      </DialogContent>
    </Dialog>
  );
}

export default Call24OrderDetailContainer;
