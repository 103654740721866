import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Button, Snackbar } from "@material-ui/core";
import { format } from "date-fns";

import useSnackbar from "../../hooks/useSnackbar";
import ExtraExceptHolidayList from "../../components/corporation/ExtraExceptHolidayList";
import Notice from "../../components/common/Notice";
import { destructResponse } from "../../lib/hasura/common";
import {
  getCorpExceptHoliday,
  createCorpExceptHoliday,
  deleteCorpExceptHoliday,
} from "../../lib/hasura/extraCharge";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import { ExceptHolidayType } from "./extraTypes";

interface HolidayChargeContainerProps {
  corpId: string;
  corpName: string;
}

function CorporationExceptHolidayContainer({
  corpId,
  corpName,
}: HolidayChargeContainerProps) {
  const dispatch = useDispatch();
  const [addHoliday, setAddHoliday] = useState<ExceptHolidayType[]>([]); // 등록할 제외휴일
  const [holidayData, setHolidayData] = useState<ExceptHolidayType[]>([]); // 등록된 제외휴일

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  // mounted
  useEffect(() => {
    fetchHolidayData();
  }, [corpId]);

  const fetchHolidayData = async () => {
    dispatch(startGlobalLoading());
    try {
      const response = await destructResponse<ExceptHolidayType[]>(
        "corporations_corporationexceptholiday",
        () => getCorpExceptHoliday(corpId)
      );
      setHolidayData(response);
    } catch (err) {
      console.log(err);
      openSnackbar("제외된 휴일을 불러오는데 실패하였습니다.", true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const onCreate = async (e: React.FormEvent) => {
    e.preventDefault();
    const exceptMsg = `제외된 휴일로 등록하시겠습니까?`;
    if (!confirm(exceptMsg)) return;

    dispatch(startGlobalLoading());
    try {
      if (addHoliday.length === 0) throw new Error("등록한 휴일이 없습니다.");

      // 저장된 holidayData와 비교하여 중복여부 확인
      const isDuplicated = addHoliday.map((add) => {
        return holidayData.some(
          ({ except_date }) => except_date === add.except_date
        );
      });
      const hasTrue = isDuplicated.some((value) => value === true);
      if (hasTrue) throw new Error("이미 등록된 휴일이 있습니다.");

      await createCorpExceptHoliday(corpId, addHoliday);
      await fetchHolidayData();
      setAddHoliday([]);
    } catch (err) {
      const failMsg = (err as Error).message || "휴일 제외에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const onChange = (date: Date | null) => {
    if (date === null) return alert("날짜를 다시 선택해주세요.");
    const formatDate = format(date, "yyyy-MM-dd");

    // 먼저 선택한 addHoliday와 비교하여 중복여부 확인
    const isDuplicate = addHoliday.some(
      ({ except_date }) => except_date === formatDate
    );
    if (isDuplicate) return alert("이미 선택한 휴일입니다.");

    setAddHoliday((prev) => [...prev, { except_date: formatDate }]);
  };

  // 추가하려는 휴일 삭제
  const addDelete = (date: string) => {
    setAddHoliday((prev) =>
      prev.filter((holiday) => holiday.except_date !== date)
    );
  };

  // 등록된 휴일 삭제
  const onDelete = async (except_date: string) => {
    const exceptMsg = `${except_date} 삭제하시겠습니까?\n해당 날짜가 공휴일이라면 다시 공휴일로 복구됩니다.`;
    if (!confirm(exceptMsg)) return;

    dispatch(startGlobalLoading());
    try {
      await deleteCorpExceptHoliday(corpId, except_date);
      await fetchHolidayData();

      openSnackbar("삭제되었습니다.", false);
    } catch (err) {
      const failMsg = (err as Error).message || "휴일 제외에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  // 등록된 휴일 전체삭제
  const onAllDelete = async () => {
    const exceptMsg = `전체 삭제하시겠습니까?\n해당 날짜가 공휴일이라면 다시 공휴일로 복구됩니다.`;
    if (!confirm(exceptMsg)) return;

    dispatch(startGlobalLoading());
    try {
      const promise = holidayData.map(
        async ({ except_date }) =>
          await deleteCorpExceptHoliday(corpId, except_date)
      );

      const result = await Promise.allSettled(promise);
      await fetchHolidayData();

      const isRejected = result.some((el) => el.status === "rejected");
      if (isRejected) throw new Error("삭제에 실패한 휴일이 있습니다.");

      openSnackbar("전체 삭제되었습니다.", false);
    } catch (err) {
      const failMsg = (err as Error).message || "삭제에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return;
    closeSnackbar();
  };

  return (
    <>
      <HolidayWrapper>
        <HolidayHeader>
          <h1 className="title">제외 휴일 DB화 ({corpName})</h1>
          <div className="description">
            *'명절' 등의 법인별 제외할 휴일을 등록합니다.
          </div>
        </HolidayHeader>
        <HolidayBody>
          <ExtraExceptHolidayList
            addHoliday={addHoliday}
            holidayData={holidayData}
            onChange={onChange}
            addDelete={addDelete}
            onDelete={onDelete}
          />
          <ButtonWrapper>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={onCreate}
            >
              제외휴일 등록
            </StyledButton>
            {holidayData.length > 0 && (
              <StyledButton
                variant="contained"
                color="secondary"
                onClick={onAllDelete}
              >
                전체 삭제
              </StyledButton>
            )}
          </ButtonWrapper>
        </HolidayBody>
      </HolidayWrapper>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}

export default CorporationExceptHolidayContainer;

const HolidayWrapper = styled.section`
  margin-top: 20px;
  text-align: center;
`;

const HolidayHeader = styled.header`
  padding-bottom: 1.5rem;

  .title {
    margin: 10px;
    font-size: 1.7rem;
  }
  .description {
    font-size: 18px;
    font-weight: 800;
    color: #9e9e9e;
  }
`;

const HolidayBody = styled.section`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.section`
  display: flex;
  margin: 1rem auto;
  gap: 4rem;
`;

const StyledButton = styled(Button)`
  margin: 2rem auto !important;
  width: 10rem;
  height: 3rem;
  font-size: 1rem;
`;
