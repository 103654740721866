import { CORPVEHICLE } from "../../lib/constants/constants";
import {
  truckWeightFiveLower,
  truckWeightFiveUpper,
} from "../../lib/hasura/corporations";
import { AlgoquickChargeType, hasuraTruckOptionsToKorean } from "./types";

/* 트럭이름 조합 */
export const truckNaming = (truck: string) => {
  // 트럭 기본요금
  if (8 <= truck.length && truck.length <= 10) return CORPVEHICLE[truck];
  // 트럭 옵션요금
  else if (
    truck.includes("truck_1t") ||
    truck.includes("truck_5t") ||
    truck.includes("truck_8t")
  )
    return `${CORPVEHICLE[truck.substring(0, 8)]}/${
      hasuraTruckOptionsToKorean[truck.substring(8)]
    }`;
  else if (
    truck.includes("truck_11t") ||
    truck.includes("truck_14t") ||
    truck.includes("truck_15t") ||
    truck.includes("truck_18t") ||
    truck.includes("truck_25t")
  )
    return `${CORPVEHICLE[truck.substring(0, 9)]}/${
      hasuraTruckOptionsToKorean[truck.substring(9)]
    }`;
  else if (
    truck.includes("truck_1_4t") ||
    truck.includes("truck_2_5t") ||
    truck.includes("truck_3_5t")
  )
    return `${CORPVEHICLE[truck.substring(0, 10)]}/${
      hasuraTruckOptionsToKorean[truck.substring(10)]
    }`;
};

/* 표준요금 조합 */
export const standardCharge = async (
  charge_algoquickcharge: AlgoquickChargeType,
  vehicle_type: string
) => {
  let truckWeight = "";
  let truckData = "";
  let truckCharge = 0;

  // 오토바이-다마스-라보
  if (!vehicle_type.includes("truck"))
    return charge_algoquickcharge[vehicle_type];
  else {
    // 트럭 기본요금
    if (8 <= vehicle_type.length && vehicle_type.length <= 10) {
      truckWeight = vehicle_type;
      truckCharge = charge_algoquickcharge[vehicle_type];
      truckData = "_truck";
    }
    // 트럭 옵션요금
    else if (
      vehicle_type.includes("truck_1t") ||
      vehicle_type.includes("truck_5t") ||
      vehicle_type.includes("truck_8t")
    ) {
      truckWeight = vehicle_type.substring(0, 8);
      truckCharge = charge_algoquickcharge[truckWeight];
      truckData = vehicle_type.substring(8);
    } else if (
      vehicle_type.includes("truck_11t") ||
      vehicle_type.includes("truck_14t") ||
      vehicle_type.includes("truck_15t") ||
      vehicle_type.includes("truck_18t") ||
      vehicle_type.includes("truck_25t")
    ) {
      truckWeight = vehicle_type.substring(0, 9);
      truckCharge = charge_algoquickcharge[truckWeight];
      truckData = vehicle_type.substring(9);
    } else if (
      vehicle_type.includes("truck_1_4t") ||
      vehicle_type.includes("truck_2_5t") ||
      vehicle_type.includes("truck_3_5t")
    ) {
      truckWeight = vehicle_type.substring(0, 10); // truck_3_5t
      truckCharge = charge_algoquickcharge[truckWeight]; // 110,000 (3.5톤 기본 트럭값)
      truckData = vehicle_type.substring(10); // _freezer_box
    }
    return await isTruckTons(truckWeight, truckCharge, truckData);
  }
};

/* 트럭 1~5톤 및 5톤 초과 구분 */
const isTruckTons = async (
  truckWeight: string,
  truckCharge: number,
  truckData: string
) => {
  if (
    truckWeight === "truck_1t" ||
    truckWeight === "truck_1_4t" ||
    truckWeight === "truck_2_5t" ||
    truckWeight === "truck_3_5t" ||
    truckWeight === "truck_5t"
  ) {
    const fiveLower = await truckWeightFiveLower(truckCharge);
    for (let keys in fiveLower) {
      if (keys.split("_charge")[0] === truckData.slice(1)) {
        return fiveLower[keys];
      } else if (truckData.slice(1) === "cargo") {
        /* 카고일 때 truck_charge 요금을 등록! */
        return fiveLower["truck_charge"];
      }
    }
  }
  // 트럭 5톤 초과
  else {
    const fiveUpper = await truckWeightFiveUpper(truckCharge);
    for (let keys in fiveUpper) {
      if (keys.split("_charge")[0] === truckData.slice(1)) {
        return fiveUpper[keys];
      } else if (truckData.slice(1) === "cargo") {
        /* 카고일 때 truck_charge 요금을 등록! */
        return fiveUpper["truck_charge"];
      }
    }
  }
};
