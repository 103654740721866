export const formatDate = (time) => {
  if (time == null || time?.trim() === "") {
    return "";
  }

  const date = new Date(time);
  return (
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1 < 10
      ? `0${date.getMonth() + 1}`
      : date.getMonth() + 1) +
    "-" +
    (date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()) +
    " " +
    (date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()) +
    ":" +
    (date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes())
  );
};

export const getTodayDate = () => new Date().toISOString().split("T")[0];
export const toISODateString = (date = new Date()) =>
  [
    date.getFullYear(),
    String(date.getMonth() + 1).padStart(2, "0"),
    String(date.getDate()).padStart(2, "0"),
  ].join("-");

/* 금일 주문 검색 parameter (오늘 정각날짜 UTC) */
export const getUTCTodayDate = () => {
  const d = new Date();
  d.setDate(d.getDate() - 1);
  const date = d.toISOString().split("T")[0];
  return `${date}T15:00:00`;
};

/* 과거 주문 검색 parameter (시작일 종료일 UTC) */
export const getUTCDatetime = (flag, date) => {
  const d = new Date(Date.parse(date));
  if (flag === "start") {
    d.setDate(d.getDate() - 1);
  }

  const convertDate = d.toISOString().split("T")[0];
  return `${convertDate}T15:00:00`;
};

/* 과거 주문 검색 (시작일 / 종료일) */
export const getCurrentDate = () => {
  const d = new Date();
  d.setDate(d.getDate() - 7);
  return d.toISOString().split("T")[0];
};
export const getPastOrderDate = () => {
  const d = new Date();
  d.setDate(d.getDate() - 1);
  return d.toISOString().split("T")[0];
};

/* complete_request_time을 형변환하여 formatDate 적용 */
export const formatCompleteTime = (time) => {
  /* 도착시간범위 [\"2022-07-15 01:00:00+00\",\"2022-07-15 01:30:00+00\") */
  if (time.startsWith("[") && !time.endsWith(",)")) {
    const range = time.slice(1, time.length - 1).split(",");

    return `${formatDate(range[0])} ~ ${formatDate(range[1])}`;
  } else if (time.startsWith("[") && time.endsWith(",)")) {
    /* 도착시간이후 [\"2022-07-15 01:00:00+00\",) */
    const upper = time.slice(1, time.length - 2);

    return `${formatDate(upper)} 이후`;
  } else if (time.startsWith("(") && time.endsWith(")")) {
    /* 도착시간이전 (,\"2022-07-15 01:00:00+00\") */
    const lower = time.slice(2, time.length - 1);

    return `${formatDate(lower)} 이전`;
  } else {
    return time;
  }
};
