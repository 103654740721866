import { Nullable } from "../../lib/constants/orders/types";

export type KakaoType = {
  id: string;
  kakao_phone_number: string;
  kakao_status: string;
};

// 카카오톡 발송 내역
export type KakaoReports = {
  cmid: string | null;
  created: string;
  id: number;
  manual: boolean;
  phone_number: string;
  result: string | null;
  status: string;
};

// 상세주문 요금
export type AlgoOrders = {
  bulk_charge: number;
  card_total_amt: number | null;
  carpool_charge: number;
  corporate_credit_amt: number;
  discount_charge: number;
  express_charge: number;
  holiday_charge: number;
  loading: string | null;
  loading_charge: number;
  loading_level: number;
  other_charge: number;
  payment_charge: number;
  round_trip_charge: number;
  tid: string | null;
  time_charge: number;
  unloading: string | null;
  vat: number;
  vehicle_charge: number;
  weather_charge: number;
  insurance_charge: number;
};

// 기사 이동 좌표
type Routes = { datetime: string; latitude: string; longitude: string };

// 유저 정보
type OrderMember = {
  corporations_corporation: {
    billing_department_enable: boolean;
    costcenter_change_memo_enable: boolean;
    discount_rate: number;
    name: string;
  } | null;
  corporations_department: {
    name: string;
  } | null;
  username: string;
};

// 상세주문정보 전체 타입
export type OrderByPkType = {
  algoquick_kakaoreports: KakaoReports[];
  algoquick_orders: AlgoOrders[];
  allocated: string | null;
  aq_pickup_request_time: string | null;
  call24_order_number: string | null;
  call24_order_receipt: string | null;
  channel: string;
  client_department: string;
  client_name: string;
  client_phone_number: string;
  complete_request_time: string | null;
  completed: string | null;
  consignment: any;
  corporate_account_memo: string | null;
  costcenter: string | null;
  costcenter_type: string;
  costcenter_change_memo: string | null;
  created: string;
  delivery_charge: number;
  distance: number;
  express: boolean;
  external_isd_order_number: string | null;
  extra_charge: number;
  id: string;
  is_isd_order: boolean;
  is_safety_number: boolean;
  isd_distance: number;
  isd_order_number: string | null;
  isd_pickup_request_time: string | null;
  location_charge: number;
  memo: string | null;
  modified: string;
  note: string | null;
  payment: string;
  picked_up: string | null;
  receiver_address: string;
  receiver_address_detail: string | null;
  receiver_bcode: string;
  receiver_jibun_address: string;
  receiver_latitude: string;
  receiver_longitude: string;
  receiver_name: string;
  receiver_phone_number: string;
  receiver_staff: string;
  rider_name: string | null;
  rider_phone_number: string | null;
  rider_vehicle_number: string | null;
  route: Routes[];
  sender_address: string;
  sender_address_detail: string | null;
  sender_bcode: string;
  sender_jibun_address: string;
  sender_latitude: string;
  sender_longitude: string;
  sender_name: string;
  sender_phone_number: string;
  sender_sign: string;
  sender_staff: string;
  status: string;
  trip: string;
  usersUserByRelatedUserId: Nullable<OrderMember>;
  users_user: Nullable<OrderMember>;
  vehicle_option: string;
  vehicle_type: string;
};

// 주문정보 디테일 정보 제출
export type ChangeableType = {
  [x: string]: string | number | boolean;
  isd_order_number: string;
  external_isd_order_number: string;
  call24_order_number: string;
  location_charge: number;
  extra_charge: number;
  delivery_charge: number;
  express: boolean;
  status: string;
  note: string;
  rider_name: string;
  rider_phone_number: string;
};

// 재주문
export type ReOrderType = {
  id: string;
  channel: string;
  created: string;
  modified: string;
  client_name: string;
  sender_name: string;
  receiver_name: string;
  receiver_address: string;
  receiver_jibun_address: string;
  memo: string;
  username: string;
};

// Call24
export type Call24Type = {
  id: string;
  sender: string;
  receiver: string;
  charge: number;
  senderBcode: string;
  receiverBcode: string;
  fee: number;
  vehicle_type: string;
  weight: string;
  option: string;
  multiCargoGub: boolean;
  express: boolean;
  trip: boolean;
  startPlanDt: boolean;
  note: string;
  firstType: boolean;
};

// 인수증 이미지
export type ReceiptImgType = {
  filename: string;
  url: string;
};

// 인수증 업로드
export type ReceiptUploadType = {
  key: string;
  success: boolean;
  url: string;
};

// 인수증 파일 (File 객체를 타입으로 지정했기때문에 일단 해당코드는 사용하지 않았다)
export type ReceiptFileType = {
  [x: string]: string | number | Date;
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
};

// 주문정보 유저수정
export type UserIdType = {
  id: string;
};

export const DEFAULT_ORDER_BY_PK = {
  algoquick_kakaoreports: [
    {
      cmid: "",
      created: "",
      id: 0,
      manual: false,
      phone_number: "",
      result: "",
      status: "",
    },
  ],
  algoquick_orders: [
    {
      bulk_charge: 0,
      card_total_amt: 0,
      carpool_charge: 0,
      corporate_credit_amt: 0,
      discount_charge: 0,
      express_charge: 0,
      holiday_charge: 0,
      loading: "",
      loading_charge: 0,
      loading_level: 0,
      other_charge: 0,
      payment_charge: 0,
      round_trip_charge: 0,
      tid: "",
      time_charge: 0,
      unloading: "",
      vat: 0,
      vehicle_charge: 0,
      weather_charge: 0,
      insurance_charge: 0,
    },
  ],
  allocated: "",
  aq_pickup_request_time: "",
  call24_order_number: "",
  call24_order_receipt: "",
  channel: "",
  client_department: "",
  client_name: "",
  client_phone_number: "",
  complete_request_time: "",
  completed: "",
  consignment: {},
  corporate_account_memo: "",
  costcenter: null,
  costcenter_type: "DEPARTMENT",
  costcenter_change_memo: null,
  created: "",
  delivery_charge: 0,
  distance: 0,
  express: false,
  external_isd_order_number: "",
  extra_charge: 0,
  id: "",
  is_isd_order: false,
  is_safety_number: false,
  isd_distance: 0,
  isd_order_number: "",
  isd_pickup_request_time: "",
  location_charge: 0,
  memo: "",
  modified: "",
  note: "",
  payment: "",
  picked_up: "",
  receiver_address: "",
  receiver_address_detail: "",
  receiver_bcode: "",
  receiver_jibun_address: "",
  receiver_latitude: "",
  receiver_longitude: "",
  receiver_name: "",
  receiver_phone_number: "",
  receiver_staff: "",
  rider_name: "",
  rider_phone_number: "",
  rider_vehicle_number: "",
  route: [{ datetime: "", latitude: "", longitude: "" }],
  sender_address: "",
  sender_address_detail: "",
  sender_bcode: "",
  sender_jibun_address: "",
  sender_latitude: "",
  sender_longitude: "",
  sender_name: "",
  sender_phone_number: "",
  sender_sign: "",
  sender_staff: "",
  status: "",
  trip: "",
  usersUserByRelatedUserId: {
    corporations_corporation: {
      billing_department_enable: false,
      costcenter_change_memo_enable: false,
      discount_rate: 0,
      name: "",
    },
    corporations_department: {
      name: "",
    },
    username: "",
  },
  users_user: {
    corporations_corporation: {
      billing_department_enable: false,
      costcenter_change_memo_enable: false,
      discount_rate: 0,
      name: "",
    },
    corporations_department: {
      name: "",
    },
    username: "",
  },
  vehicle_option: "",
  vehicle_type: "",
};

export const DEFAULT_CHANGEABLE = {
  isd_order_number: "",
  external_isd_order_number: "",
  call24_order_number: "",
  location_charge: 0,
  extra_charge: 0,
  delivery_charge: 0,
  express: false,
  status: "",
  note: "",
  rider_name: "",
  rider_phone_number: "",
};

export const DEFAULT_REORDER = {
  id: "",
  channel: "",
  created: "",
  modified: "",
  client_name: "",
  sender_name: "",
  receiver_name: " ",
  receiver_address: "",
  receiver_jibun_address: "",
  memo: "",
  username: "",
};

export const DEFAULT_CALL24 = {
  id: "",
  sender: "",
  receiver: "",
  charge: 0,
  senderBcode: "",
  receiverBcode: "",
  fee: 0,
  vehicle_type: "",
  weight: "",
  option: "",
  multiCargoGub: false,
  express: false,
  trip: false,
  startPlanDt: false,
  note: " ",
  firstType: false,
};

export const DEFAULT_RECEIPT_IMG = {
  filename: "",
  url: "",
};

export const DEFAULT_CORPORATION = {
  name: "",
  billing_department_enable: false,
  costcenter_change_memo_enable: false,
};

export const DEFAULT_EXCHARGES = {
  bulk_charge: 0,
  card_total_amt: null,
  carpool_charge: 0,
  corporate_credit_amt: 0,
  discount_charge: 0,
  express_charge: 0,
  holiday_charge: 0,
  loading: null,
  loading_charge: 0,
  loading_level: 0,
  other_charge: 0,
  payment_charge: 0,
  round_trip_charge: 0,
  tid: "",
  time_charge: 0,
  unloading: null,
  vat: 0,
  vehicle_charge: 0,
  weather_charge: 0,
  insurance_charge: 0,
};

export const swapOrderStatus = [
  {
    value: "RESERVATION",
    label: "예약",
  },
  {
    value: "WAITING",
    label: "대기",
  },
  {
    value: "RECEIPT",
    label: "접수",
  },
  {
    value: "ALLOCATION",
    label: "배차",
  },
  {
    value: "BEFORE_PICK_UP",
    label: "픽업대기",
  },
  {
    value: "PICK_UP",
    label: "픽업",
  },
  {
    value: "BEFORE_COMPLETION",
    label: "완료대기",
  },
  {
    value: "COMPLETION",
    label: "완료",
  },
  {
    value: "CANCELLATION",
    label: "취소",
  },
];
