import { downloadCharge } from "../../lib/api/orders";
import { PostDataType, ConvertedDataType } from "./types";
import {
  convertVehicleTypeToLargeCharge,
  convertTruckOptionToLargeCharge,
} from "../../pages/GroupOrder/utils";

/**
 * @description postData => POST API 데이터
 * @description fetchData => postData.slice(i * 10, (i + 1) * 10)
 * @description convertedData => ...fetchData
 */
export const LargeChargeCalculate = async (
  channel: string,
  removedHeaderData: string[][]
) => {
  let postData: PostDataType[] = [];
  let convertedData: ConvertedDataType[] = [];

  /* 2_5T/카고/리프트 => 2_5T/CARGO/LIFT */
  const truckName = (truckOpt: string) => {
    const arr = truckOpt.split("/");
    const arr1 = arr.map((el) => {
      if (
        el === "카고" ||
        el === "호루" ||
        el === "탑차" ||
        el === "윙바디" ||
        el === "냉장탑차" ||
        el === "냉장윙바디" ||
        el === "냉동탑차" ||
        el === "냉동윙바디"
      ) {
        return convertTruckOptionToLargeCharge(el);
      }
      if (el === "리프트") {
        return "LIFT";
      } else {
        return el;
      }
    });
    return arr1.join("/");
  };
  try {
    /* 엑셀 정보를 API 데이터로 변환 */
    for (let value of removedHeaderData) {
      if (value[3].indexOf("트럭") < 0) {
        postData = [
          ...postData,
          {
            sender: value[1],
            receiver: value[2],
            vehicleType: convertVehicleTypeToLargeCharge(value[3]),
            vehicleOption: "",
          },
        ];
      } else {
        /* 트럭2_5t/카고/리프트 */
        postData = [
          ...postData,
          {
            sender: value[1],
            receiver: value[2],
            vehicleType: convertVehicleTypeToLargeCharge(value[3].slice(0, 2)), // TRUCK
            vehicleOption: truckName(value[3].slice(2)).toUpperCase(), // 2_5T/CARGO/LIFT
          },
        ];
      }
    }

    // 10개 이하 => tenCount : 0 => slice(0, 10)
    // 10~19개  => tenCount : 1 => slice(0, 10) slice(10, 20)
    // 20~29개  => tenCount : 2 => slice(0, 10) slice(10, 20) slice(20, 30)
    const tenCount = Math.floor(postData.length / 10);

    for (let i = 0; i <= tenCount; i++) {
      const slices = postData.slice(i * 10, (i + 1) * 10);

      // 빈 배열 데이터는 제외 (10개 단위의 경우)
      if (slices.length > 0) {
        const { data }: { data: ConvertedDataType[] } = await downloadCharge(
          channel,
          slices
        );
        convertedData = [...convertedData, ...data];
      }
    }

    if (convertedData) {
      return registExcel(convertedData, removedHeaderData);
    }
  } catch (error) {
    console.log(error);
  }
};

/* convertedData 요금을 엑셀에 등록 (key를 활용해야해서 for-in) */
const registExcel = (
  convertedData: ConvertedDataType[],
  removedHeaderData: string[][]
) => {
  for (let index in removedHeaderData) {
    removedHeaderData[index][0] = (Number(index) + 1).toString(); // NO.

    if (convertedData[index].charge === undefined) {
      if (convertedData[index].senderAddress === "error") {
        // 주소 에러
        removedHeaderData[index][4] = "주소 에러";
      } else {
        // 모든 요금 호출하는 정상 데이터 (추후 사용 예정)
        removedHeaderData[index][4] = "차량 미기입";
      }
    } else if (convertedData[index].charge === "error") {
      // 차종 에러
      removedHeaderData[index][4] = "차량 기입 오류";
    } else {
      removedHeaderData[index][4] = convertedData[index].charge.toString();
      removedHeaderData[index][5] = convertedData[index].distance.toString();
    }
  }
  return removedHeaderData;
};
