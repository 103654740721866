import { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Button, Snackbar } from "@material-ui/core";
import useSnackbar from "../../hooks/useSnackbar";
import ExtraBulkChargeList from "../../components/corporation/ExtraBulkChargeList";
import Notice from "../../components/common/Notice";
import { destructResponse } from "../../lib/hasura/common";
import {
  createCorpBulkCharge,
  getCorpBulkCharge,
  updateCorpBulkCharge,
  deleteCorpBulkCharge,
} from "../../lib/hasura/extraCharge";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import { BulkChargeType } from "./extraTypes";
import { validateBulkCharge } from "./utils";

const DEFAULT_BULK_CHARGES = [
  { bulk_score: 0, weight: 0, bulk_charge: 0 },
  { bulk_score: 1, weight: 0, bulk_charge: 0 },
  { bulk_score: 2, weight: 0, bulk_charge: 0 },
  { bulk_score: 3, weight: 0, bulk_charge: 0 },
  { bulk_score: 4, weight: 0, bulk_charge: 0 },
  { bulk_score: 5, weight: 0, bulk_charge: 3000 },
  { bulk_score: 6, weight: 0, bulk_charge: 3000 },
  { bulk_score: 7, weight: 0, bulk_charge: 5000 },
  { bulk_score: 8, weight: 0, bulk_charge: 7000 },
  { bulk_score: 9, weight: 0, bulk_charge: 7000 },
  { bulk_score: 10, weight: 0, bulk_charge: 8000 },
  { bulk_score: 11, weight: 0, bulk_charge: 8000 },
  { bulk_score: 12, weight: 0, bulk_charge: 8000 },
  { bulk_score: 13, weight: 0, bulk_charge: 8000 },
  { bulk_score: 0, weight: 20, bulk_charge: 3000 },
  { bulk_score: 1, weight: 20, bulk_charge: 3000 },
  { bulk_score: 2, weight: 20, bulk_charge: 3000 },
  { bulk_score: 3, weight: 20, bulk_charge: 3000 },
  { bulk_score: 4, weight: 20, bulk_charge: 3000 },
  { bulk_score: 5, weight: 20, bulk_charge: 5000 },
  { bulk_score: 6, weight: 20, bulk_charge: 5000 },
  { bulk_score: 7, weight: 20, bulk_charge: 7000 },
  { bulk_score: 8, weight: 20, bulk_charge: 7000 },
  { bulk_score: 9, weight: 20, bulk_charge: 7000 },
  { bulk_score: 10, weight: 20, bulk_charge: 8000 },
  { bulk_score: 11, weight: 20, bulk_charge: 8000 },
  { bulk_score: 12, weight: 20, bulk_charge: 8000 },
  { bulk_score: 13, weight: 20, bulk_charge: 8000 },
  { bulk_score: 0, weight: 30, bulk_charge: 4000 },
  { bulk_score: 1, weight: 30, bulk_charge: 4000 },
  { bulk_score: 2, weight: 30, bulk_charge: 4000 },
  { bulk_score: 3, weight: 30, bulk_charge: 4000 },
  { bulk_score: 4, weight: 30, bulk_charge: 4000 },
  { bulk_score: 5, weight: 30, bulk_charge: 6000 },
  { bulk_score: 6, weight: 30, bulk_charge: 6000 },
  { bulk_score: 7, weight: 30, bulk_charge: 8000 },
  { bulk_score: 8, weight: 30, bulk_charge: 8000 },
  { bulk_score: 9, weight: 30, bulk_charge: 8000 },
  { bulk_score: 10, weight: 30, bulk_charge: 9000 },
  { bulk_score: 11, weight: 30, bulk_charge: 9000 },
  { bulk_score: 12, weight: 30, bulk_charge: 9000 },
  { bulk_score: 13, weight: 30, bulk_charge: 9000 },
  { bulk_score: 0, weight: 40, bulk_charge: 6000 },
  { bulk_score: 1, weight: 40, bulk_charge: 6000 },
  { bulk_score: 2, weight: 40, bulk_charge: 6000 },
  { bulk_score: 3, weight: 40, bulk_charge: 6000 },
  { bulk_score: 4, weight: 40, bulk_charge: 6000 },
  { bulk_score: 5, weight: 40, bulk_charge: 7000 },
  { bulk_score: 6, weight: 40, bulk_charge: 7000 },
  { bulk_score: 7, weight: 40, bulk_charge: 9000 },
  { bulk_score: 8, weight: 40, bulk_charge: 9000 },
  { bulk_score: 9, weight: 40, bulk_charge: 9000 },
  { bulk_score: 10, weight: 40, bulk_charge: 10000 },
  { bulk_score: 11, weight: 40, bulk_charge: 10000 },
  { bulk_score: 12, weight: 40, bulk_charge: 10000 },
  { bulk_score: 13, weight: 40, bulk_charge: 10000 },
];

interface BulkChargeContainer {
  corpId: string;
  corpName: string;
}

function CorporationBulkChargeContainer({
  corpId,
  corpName,
}: BulkChargeContainer) {
  const dispatch = useDispatch();
  const [addBulk, setAddBulk] = useState<BulkChargeType[]>([]); // 등록할 과적요금
  const [bulkData, setBulkData] = useState<BulkChargeType[]>([]); // 과적요금 데이터

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  // mounted
  useEffect(() => {
    fetchBulkData();
  }, [corpId]);

  const fetchBulkData = async () => {
    dispatch(startGlobalLoading());
    try {
      const response = await destructResponse<BulkChargeType[]>(
        "corporations_corporationbulkcharge",
        () => getCorpBulkCharge(corpId)
      );
      setBulkData(response);
    } catch (err) {
      console.log(err);
      openSnackbar("과적요금을 불러오는데 실패하였습니다.", true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  // 전체 과적요금 생성
  const onAllRegist = async () => {
    const confirmMsg = `${corpName}의 과적요금을 생성하시겠습니까?`;
    if (!confirm(confirmMsg)) return;

    dispatch(startGlobalLoading());
    try {
      await createCorpBulkCharge(corpId, DEFAULT_BULK_CHARGES);
      await fetchBulkData();
    } catch (err) {
      const failMsg =
        (err as Error).message || "과적요금 등록에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const onChange = (score: number, weight: number, charge: number) => {
    setAddBulk((prev) => {
      return prev
        .filter((data) => data.bulk_score !== score || data.weight !== weight)
        .concat({
          bulk_score: score,
          weight: weight,
          bulk_charge: charge,
        });
    });
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const confirmMsg = `이대로 과적요금을 등록하시겠습니까?`;
    if (!confirm(confirmMsg)) return;

    dispatch(startGlobalLoading());
    try {
      addBulk.map(({ bulk_charge }) => {
        const invalidMessage = validateBulkCharge(bulk_charge);
        if (invalidMessage) throw new Error(invalidMessage);
      });

      const promise = addBulk.map((data) => updateCorpBulkCharge(corpId, data));
      const result = await Promise.allSettled(promise);
      await fetchBulkData();

      const isRejected = result.some((el) => el.status === "rejected");
      if (isRejected) throw new Error("등록에 실패한 요금이 있습니다.");

      openSnackbar("요금이 등록되었습니다.", false);
    } catch (err) {
      console.log(err);
      const failMsg = (err as Error).message || "요금 등록에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const onAllDelete = async () => {
    const deleteMsg = `과적요금을 전체 삭제하시곘습니까?\n전체 삭제시 "기존 과적요금"으로 자동 적용됩니다.`;
    if (!confirm(deleteMsg)) return;

    dispatch(startGlobalLoading());
    try {
      await deleteCorpBulkCharge(corpId);
      await fetchBulkData();
      openSnackbar("삭제가 완료되었습니다.", false);
    } catch (err) {
      const failMsg =
        (err as Error).message || "과적요금 삭제에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return;
    closeSnackbar();
  };

  return (
    <>
      <BulkWrapper>
        <BulkHeader>
          <h1 className="title">과적요금 DB화 ({corpName})</h1>
          <div className="description">
            *과적요금은 부분삭제가 불가하며, 전체삭제시 "기존 과적요금"으로 자동
            적용됩니다.
          </div>
        </BulkHeader>
        {bulkData.length > 0 ? (
          <BulkBody>
            <ExtraBulkChargeList bulkData={bulkData} onChange={onChange} />
            <ButtonWrapper>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                요금 등록
              </StyledButton>
              <StyledButton
                variant="contained"
                color="secondary"
                onClick={onAllDelete}
              >
                전체 삭제
              </StyledButton>
            </ButtonWrapper>
          </BulkBody>
        ) : (
          <NoneList>
            등록된 요금이 없습니다.
            <StyledButton
              variant="outlined"
              color="primary"
              onClick={onAllRegist}
            >
              과적 기본요금 생성하기
            </StyledButton>
          </NoneList>
        )}
      </BulkWrapper>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}

export default CorporationBulkChargeContainer;

const BulkWrapper = styled.section`
  margin-top: 20px;
  text-align: center;
`;

const BulkHeader = styled.header`
  padding-bottom: 1.5rem;

  .title {
    margin: 10px;
    font-size: 1.7rem;
  }
  .description {
    font-size: 18px;
    font-weight: 800;
    color: #9e9e9e;
  }
`;

const BulkBody = styled.section`
  display: flex;
  flex-direction: column;
`;

const NoneList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10rem 2rem;
  font-size: 20px;
`;

const ButtonWrapper = styled.section`
  display: flex;
  margin: 1rem auto;
  gap: 4rem;
`;

const StyledButton = styled(Button)`
  margin: 2rem auto !important;
  width: 15rem;
  height: 3rem;
  font-size: 1rem;
`;
