import React, { useState, useEffect } from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Snackbar } from "@material-ui/core";

import UserDetail from "../../components/user/UserDetail";
import Notice from "../../components/common/Notice";
import useSnackbar from "../../hooks/useSnackbar";

import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import { getCorporationsWithDepartments } from "../../lib/hasura/corporations";
import { destructResponse } from "../../lib/hasura/common";
import { validateAddUser } from "../../lib/validate";
import {
  DEFAULT_CORP,
  DEFAULT_DEPART,
  DEFAULT_USER,
  UserType,
  CorpListType,
  DepartListType,
} from "./types";
import {
  checkUserName,
  checkPhone,
  registUser,
  postCreateUser,
} from "../../lib/api/users";
import { parseError } from "../../lib/api/base";

function UserAddContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userState = useSelector(({ user }: RootStateOrAny) => user.user);

  const [user, setUser] = useState<UserType>(DEFAULT_USER);
  const [corp, setCorp] = useState<CorpListType[]>(DEFAULT_CORP);
  const [depart, setDepart] = useState<DepartListType[]>(DEFAULT_DEPART);
  const [admin, setAdmin] = useState(false);

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  const fetchData = async () => {
    const channel = userState?.channel || "algoquick";
    dispatch(startGlobalLoading());
    try {
      const corpList = await destructResponse<CorpListType[]>(
        "corporations_corporation",
        () => getCorporationsWithDepartments(channel)
      );
      setCorp(corpList);
    } catch {
      const msg = "법인 정보를 불러올 수 없습니다. 개발팀에 문의해주세요.";
      openSnackbar(msg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  // mounted
  useEffect(() => {
    fetchData();
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    switch (name) {
      case "corporation":
        if (!value) {
          // 법인 삭제
          setUser({
            ...user,
            corporation: "",
            corporation_name: "",
            department: " ",
          });
          setDepart([]);
        } else {
          // 법인 변경
          const newValue: any = value;
          setUser({
            ...user,
            corporation: newValue.id,
            corporation_name: newValue.name, // 법인명 추출
            department: " ",
          });
          const { corporations_departments } = corp.filter(
            (option) => option.id === newValue.id
          )[0];
          setDepart(corporations_departments);
        }
        break;
      default:
        setUser({
          ...user,
          [name]: value,
        });
        break;
    }
  };

  const handleUserAdmin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setAdmin(checked);
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(startGlobalLoading());
    try {
      const invalidMessage = validateAddUser(user, admin);
      if (invalidMessage !== "") return openSnackbar(invalidMessage, true);

      // 아이디 및 휴대폰 중복확인
      await checkUserName(user.username);
      await checkPhone(user.phone).catch(parseError);

      // 부서명 추출
      const { name: departmentName } = depart.find(
        (option) => option.id === user.department
      ) || { name: "" };

      if (user.corporation_name) {
        // 법인회원가입
        // 이메일 기재시 "법인 이메일 인증"과 동일하게 사번(employeeNumber) 등록
        const email = user.email;
        const employeeNumber = email?.split("@")[0] || null;

        const {
          data: { id },
        } = await registUser({
          corporation_signup: true,
          username: user.username,
          password: user.password,
          passwordConfirm: user.passwordCheck,
          fullname: user.fullname,
          phone: user.phone,
          email,
          employeeNumber,
          company_name: user.corporation_name,
          department_name: departmentName,
        });
        // adminuserauth 권한 등록
        if (id && admin) await postCreateUser(id);
      } else {
        // 일반회원가입
        await registUser({
          corporation_signup: false,
          username: user.username,
          password: user.password,
          passwordConfirm: user.passwordCheck,
          fullname: user.fullname,
          phone: user.phone,
          email: user.email,
        });
      }

      const msg = admin
        ? `${user.fullname}님 신규생성 및 권한등록 되었습니다.`
        : `${user.fullname}님 신규생성 되었습니다.`;
      openSnackbar(msg, false);
    } catch (err) {
      if (typeof err === "string") openSnackbar(err, true);
      else alert(err);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    closeSnackbar();
    if (!error) history.push("/users");
  };

  return (
    <>
      <UserDetail
        row={user}
        corp={corp}
        depart={depart}
        onChange={onChange}
        onSubmit={onSubmit}
        handleUserAdmin={handleUserAdmin}
        handlePlatform={() => {}}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}

export default UserAddContainer;
