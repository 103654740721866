import {
  localAddress,
  localKeyword,
  coord2regioncode,
} from "../../lib/api/base";
import {
  CorpChargeDataType,
  ChargeLocationType,
  AddressType,
  ChargeDataType,
  UploadDataType,
  normalVehicleToEnglish,
  hasuraTruckOptionsToEnglish,
  CorpChargeByLocVeh,
} from "../../containers/singleCorpCharge/types";
import { truckNaming } from "../../containers/singleCorpCharge/SingleCorpChargeCalculate";
import { sleep } from "../../lib/core";
import { CORPVEHICLE } from "../../lib/constants/constants";

/* 출도착지 검색 API */
export const registAddress = (
  address: string,
  setStateAction: React.Dispatch<React.SetStateAction<AddressType[]>>,
  setPaginate: React.Dispatch<React.SetStateAction<number>>
) => {
  Promise.all([localAddress(address), localKeyword(address, 5)])
    .then((response) => {
      const merge_search = [].concat(...response);
      if (merge_search.length === 0) {
        alert("검색 결과가 없습니다.");
      } else {
        setStateAction(merge_search);
        setPaginate(1); // pagination 초기화
      }
    })
    .catch((err) => console.log(err));
};

/* 데이터 추가시 유효성 검사 */
export const isValidMessage = (
  chargeList: CorpChargeByLocVeh[],
  chargeData: ChargeDataType,
  truckData: string
): string => {
  // 중복 데이터 filter
  const isFiltered = chargeList.filter(
    ({ chargeId, vehicleType }) =>
      chargeId === chargeData.AlgoQuickCharge_id &&
      vehicleType === chargeData.vehicle_type
  );
  if (isFiltered.length > 0) {
    return "중복된 주소입니다. 기존 데이터를 수정해주세요.";
  }
  if (
    chargeData.departure_id === "" ||
    chargeData.destination_id === "" ||
    chargeData.vehicle_type === ""
  ) {
    return "주소 및 차량을 정확히 선택해주세요.";
  }
  if (chargeData.vehicle_type.includes("truck") && truckData === "") {
    return "트럭 종류를 선택해주세요.";
  }
  return "";
};

/* 엑셀 업로드 데이터 변경 */
export const convertExcelToData = (
  uploadData: UploadDataType[]
): Promise<UploadDataType[]> => {
  return uploadData.reduce(
    async (
      accPromise: any,
      { departure_id, destination_id, vehicle_type, charge }: UploadDataType
    ) => {
      const acc = await accPromise; // 이전 반복의 결과를 기다림
      let initialObj = {};

      const response = (await Promise.allSettled([
        localAddress(departure_id, 1),
        localKeyword(departure_id, 1),
        localAddress(destination_id, 1),
        localKeyword(destination_id, 1),
      ])) as PromiseFulfilledResult<AddressType[]>[];

      const [
        { status: isDepartLocal, value: departLocalValue },
        { status: isDepartKeyword, value: departKeywordValue },
        { status: isDestinLocal, value: destinLocalValue },
        { status: isDestinKeyword, value: destinKeywordValue },
      ] = response;

      // 출발지 b_code
      const isDepartError = await createBcode(
        initialObj,
        isDepartLocal,
        departLocalValue,
        isDepartKeyword,
        departKeywordValue,
        "출발지"
      );
      // 도착지 b_code
      const isDestinError = await createBcode(
        initialObj,
        isDestinLocal,
        destinLocalValue,
        isDestinKeyword,
        destinKeywordValue,
        "도착지"
      );

      if (isDepartError !== undefined) throw new Error(isDepartError);
      if (isDestinError !== undefined) throw new Error(isDestinError);
      if (vehicle_type.includes("."))
        throw new Error("트럭 톤수는 밑줄(_)로 표시해야 합니다.");

      // 차량종류, 요금
      Object.assign(initialObj, {
        charge,
        vehicle_type: convertVehicleType(vehicle_type),
      });

      acc.push(initialObj);
      await sleep(1000);
      return acc;
    },
    []
  );
};

const createBcode = async (
  initialObj: object,
  localStatus: "fulfilled" | "rejected",
  localValue: AddressType[],
  keywordStatus: "fulfilled" | "rejected",
  keywordValue: AddressType[],
  text: "출발지" | "도착지"
) => {
  let message;
  if (localStatus === "fulfilled" && localValue.length > 0) {
    const { b_code } = localValue[0];
    Object.assign(
      initialObj,
      text === "출발지"
        ? { departure_id: b_code?.slice(0, -2) }
        : { destination_id: b_code?.slice(0, -2) }
    );
  } else if (keywordStatus === "fulfilled" && keywordValue.length > 0) {
    const { longitude, latitude } = keywordValue[0];
    const { b_code } = await coord2regioncode(longitude, latitude);
    Object.assign(
      initialObj,
      text === "출발지"
        ? { departure_id: b_code?.slice(0, -2) }
        : { destination_id: b_code?.slice(0, -2) }
    );
  } else {
    message = `잘못된 ${text} 주소가 있습니다.`;
    return message;
  }
};

/**
 * @returns { motorcycle, damas, truck_1_4t, truck_1_4t_cargo }
 */
const convertVehicleType = (vehicle: string): string => {
  if (vehicle.includes("트럭")) {
    const [a, b] = vehicle.slice(2).split("/");
    return b !== undefined
      ? `truck_${a.toLowerCase()}${hasuraTruckOptionsToEnglish[b]}`
      : `truck_${a.toLowerCase()}`;
  } else if (vehicle.includes("연계배송")) {
    return normalVehicleToEnglish[vehicle.split("/")[1]].toUpperCase();
  } else {
    return normalVehicleToEnglish[vehicle];
  }
};

/* 거래처 요금 다운로드 */
export const EXCEL_ROWS = [
  {
    label: "departure_id",
    format: ({ departure }: CorpChargeByLocVeh) => convertAddress(departure),
  },
  {
    label: "destination_id",
    format: ({ destination }: CorpChargeByLocVeh) =>
      convertAddress(destination),
  },
  {
    label: "vehicle_type",
    format: ({ vehicleType }: CorpChargeByLocVeh) =>
      convertVehicleTypeKorean(vehicleType),
  },
  {
    label: "charge",
    format: ({ customCharge }: CorpChargeByLocVeh) => customCharge,
  },
];

const convertAddress = (address: ChargeLocationType) => {
  const values = Object.values(address).map((obj: { name: string } | string) =>
    typeof obj === "object" ? obj.name : obj
  );
  return values.join(" ");
};

const convertVehicleTypeKorean = (vehicle: string) => {
  if (vehicle.includes("truck")) {
    return truckNaming(vehicle);
  } else {
    return CORPVEHICLE[vehicle];
  }
};
