import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import UseStatementContents from "./UseStatementContents";
import UseStatementFooter from "./UseStatementFooter";
import PdfDownloader from "../common/PdfDownloader";
import { OrderByPkType } from "../../containers/order/types";
import { ModalWrapper } from "../common/Modal";

interface UseStatementProps {
  row: OrderByPkType;
  modalRef: React.ForwardedRef<HTMLDivElement>;
  outSideUseStateModal: (e: React.MouseEvent) => void;
  closeUseStateModal: () => void;
}

function UseStatementModal({
  row,
  modalRef,
  outSideUseStateModal,
  closeUseStateModal,
}: UseStatementProps) {
  return (
    <ModalWrapper ref={modalRef} onClick={(e) => outSideUseStateModal(e)}>
      <DetailModal>
        <ModalTop>
          <CloseBtn onClick={closeUseStateModal}>
            <Close style={{ fontSize: 40 }} />
          </CloseBtn>
        </ModalTop>
        <ModalBody id="use-statement-id">
          <UseStatementContents row={row} />
          <UseStatementFooter />
        </ModalBody>
        <PdfDownloader
          rootElementId="use-statement-id"
          defaultName="사용내역서"
          clientName={row.client_name || ""}
          orderName={row.id}
        />
      </DetailModal>
    </ModalWrapper>
  );
}

export default UseStatementModal;

const DetailModal = styled.section`
  width: 900px;
  max-height: 97%;
  background-color: #fff;
  box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.15);
  overflow-y: auto;
`;

const ModalTop = styled.div`
  text-align: right;
  width: 100%;
`;

const CloseBtn = styled(Button)`
  margin: 29px 29px 0;
  padding: 0;
  min-width: auto;
`;

const ModalBody = styled.article`
  margin: 0 auto;
  text-align: left;

  width: 793.7px; // a4 width
  height: 1122.5px; // a4 height
  font-size: 14px;
  font-weight: 400;
  color: #363636;
`;
