import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  Button as MuiButton,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import ko from "date-fns/locale/ko";
import DateFnsUtils from "@date-io/date-fns";
import * as XLSX from "xlsx";
import { AxiosResponse } from "axios";

import { headRows, LogElementType } from "./types";
import { listLogs, listLogsPast, postLog } from "../../lib/hasura/users";

import {
  getTodayDate,
  getUTCDatetime,
  getCurrentDate,
  getPastOrderDate,
} from "../../lib/datetime";
import Button from "../../components/common/Button";
import CircularLoading from "../../components/common/CircularLoading";
import LogList from "../../components/log/LogList";
import { LOG_TYPE } from "../../lib/constants/constants";

const LogStatus = [
  {
    key: "today",
    label: "금일 로그 검색",
  },
  {
    key: "past",
    label: "과거 로그 검색",
  },
];

// MenuPage.tsx, Header.tsx에 중복 타입 존재함.
interface AdminUserAuthMenu {
  menu_id: string;
}

function LogContainer() {
  const [userId, ip, authLogMenu] = useSelector(
    ({ user: { user }, userAuth: { ip, userAuth } }: any) => {
      const authLogMenu = userAuth?.data.users_adminuserauth.find(
        ({ menu_id: menuId }: AdminUserAuthMenu) => menuId === "LOGS"
      );
      return [user?.id, ip, authLogMenu];
    }
  );
  const excelDownloadable = authLogMenu?.excel || false;

  // loading
  const [loading, setLoading] = useState<boolean>(false);

  // pagination
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [page, setPage] = useState<number>(0);

  // logs
  const [logs, setLogs] = useState<LogElementType[]>([]);
  const [logCount, setLogCount] = useState<number>(0);

  // search
  const [search, setSearch] = useState<string>("");
  const [mode, setMode] = useState<string>("today");
  const [start, setStart] = useState<string>("");
  const [end, setEnd] = useState<string>("");

  /* 금일 로그 검색 */
  const getFetchLogs = async (search: string) => {
    setLoading(true);

    await listLogs(search)
      .then(doFetchLogs)
      .catch((err) => console.log(err));
  };

  /* 과거 로그 검색 */
  const getFetchPastLogs = async (
    start: string,
    end: string,
    search: string
  ) => {
    setLoading(true);

    await listLogsPast(start, end, search)
      .then(doFetchLogs)
      .catch((err) => console.log(err));
  };

  const doFetchLogs = useCallback(
    ({
      data: {
        data: { users_adminuserlog: resLogs },
      },
    }: AxiosResponse) => {
      setLogs(
        resLogs.map((resLog: LogElementType, i: number) => ({
          ...resLog,
          seq: i + 1,
          userUsername: resLog.users_user?.username || "",
          userFullname: resLog.users_user?.fullname || "",
        }))
      );
      setLogCount(resLogs.length);
    },
    []
  );

  // componentDidMount
  useEffect(() => {
    getFetchLogs("")
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  /* 검색어를 입력하세요 */
  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setPage(0); // 페이지 초기화

    if (mode === "today") {
      await getFetchLogs(search)
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    } else {
      await getFetchPastLogs(
        getUTCDatetime("start", start),
        getUTCDatetime("end", end),
        search
      )
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  };

  /* 금일 로그 검색 | 과거 로그 검색 */
  const handleMode = async (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setPage(0); // 페이지 초기화
    setMode((e.target as HTMLInputElement).value);
    setStart(getCurrentDate());
    setEnd(getPastOrderDate());

    if (e.target.value === "today") {
      await getFetchLogs("")
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    } else {
      // 과거 로그 검색 클릭시 초기화
      setLogs([]);
      setLogCount(0);
    }
  };

  /* DatePicker */
  const handleStartChange = (date: any) =>
    setStart(date.toISOString().slice(0, 10));
  const handleEndChange = (date: any) =>
    setEnd(date.toISOString().slice(0, 10));

  /* pagination */
  const handleChangePage = (
    event: React.MouseEvent | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeFirst = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseFloat(event.target.value)); // 25 50 100 페이지 뷰
    setPage(0); // 페이지 뷰 변할때마다 첫페이지 렌더링
  };

  const downloadExcel = async () => {
    const sign = prompt("다운로드 사유를 5글자 이상 기재해주세요.");
    if (sign === null) return;
    if (sign.trim().length < 5) {
      alert("5글자 이상 기재해주세요.");
      return;
    }

    await postLog(
      userId,
      ip,
      LOG_TYPE.EXCEL,
      `로그기록 다운: ${sign}`,
      "로그기록"
    );
    doDownloadExcel();
  };
  const doDownloadExcel = () => {
    const fileName =
      mode === "past"
        ? `${start}-${end} 로그 기록.xlsx`
        : `${getTodayDate()} 로그 기록.xlsx`;

    const rowsToExport = logs.map((log: LogElementType) =>
      headRows.reduce(
        (acc, { id, label, format }) => ({
          ...acc,
          [label]:
            format !== undefined
              ? format(log)
              : String(log[id as keyof LogElementType]).trim(),
        }),
        {} as { [key: string]: string }
      )
    );

    const workSheet = XLSX.utils.json_to_sheet(rowsToExport);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "로그 기록");
    const output = XLSX.writeFile(workBook, fileName);
    return new Blob([output], { type: "application/xlsx" });
  };

  return (
    <>
      <OrderSearchWrapper>
        <FormControlWrapper>
          <form onSubmit={onSubmit}>
            <FormControl>
              <Select value={mode} onChange={handleMode}>
                {LogStatus.map((option) => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <StyledTextField
              onChange={handleChangeInput}
              name="search"
              value={search}
              placeholder={"검색어를 입력하세요"}
            />
            <Button type="submit">검색</Button>
            {excelDownloadable && (
              <MuiButtonMarginLeft
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => downloadExcel()}
              >
                다운
              </MuiButtonMarginLeft>
            )}
          </form>
        </FormControlWrapper>
      </OrderSearchWrapper>
      {mode === "today" ? null : (
        <PastOrderSearchWrapper>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ko}>
            <DatePicker
              label="시작일"
              margin="normal"
              id="start"
              name="start"
              format="yyyy-MM-dd"
              value={start}
              onChange={handleStartChange}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ko}>
            <DatePicker
              label="종료일"
              margin="normal"
              id="end"
              name="end"
              format="yyyy-MM-dd"
              value={end}
              onChange={handleEndChange}
            />
          </MuiPickersUtilsProvider>
        </PastOrderSearchWrapper>
      )}

      {loading ? (
        <CircularLoading />
      ) : (
        <LogList
          rows={logs}
          count={logCount}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeFirst={handleChangeFirst}
        />
      )}
    </>
  );
}

export default LogContainer;

const OrderSearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0 1rem 0;
  height: 5rem;
`;

const PastOrderSearchWrapper = styled(OrderSearchWrapper)`
  justify-content: center;
  padding-top: 1rem;
`;

const FormControlWrapper = styled.div`
  display: flex;
  width: auto;
`;

const StyledTextField = styled(TextField)`
  width: 300px;
`;

const MuiButtonMarginLeft = styled(MuiButton)`
  margin-left: 1rem !important;
`;
