import React, { useState, useCallback } from "react";
import styled from "styled-components";
import {
  postSendSMS,
  postAuthSMS,
  findUsername,
  getToken,
  resetPassword,
} from "../../lib/api/auth";

import { ModalWrapper } from "../common/Modal";
import { passwordCheck } from "../common/Login";
import { Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";

interface thresholdProps {
  tenTime: boolean;
  setTenTime: React.Dispatch<React.SetStateAction<boolean>>;
}

function AuthThreshold({ tenTime, setTenTime }: thresholdProps) {
  const [id, setId] = useState("");
  const [code, setCode] = useState(""); // 인증번호 6자리
  const [token, setToken] = useState(""); // changePassword의 header 토큰값

  const [open, setOpen] = useState(false); // 모달 view
  const [find, setFind] = useState(false); // 비밀번호 변경 view

  const handleCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const [loginState, setLoginState] = useState({
    username: "",
    phoneNumber: "",
    password: "",
    passwordConfirm: "",
  });
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { name, value },
      } = e;

      setLoginState({ ...loginState, [name]: value });
    },
    [loginState]
  );
  const { username, phoneNumber, password, passwordConfirm } = loginState;

  /* 내가 직접 끄는 모달 */
  const closeModal = () => {
    setOpen(false);
    setId("");
    setCode("");
  };
  /* postAuthSMS후 자동 종료 */
  const closing = () => {
    setOpen(false);
    setCode("");
  };

  const openPhoneModal = () => {
    if (!phoneNumber) {
      alert("휴대폰 번호를 입력해주세요");
      return;
    }
    sendSMS();
  };

  /**
   * @description [1] 문자 발송
   * @returns { id }
   */
  const sendSMS = () => {
    if (phoneNumber.length < 10) alert("휴대폰 번호를 다시 확인해주세요.");
    else {
      postSendSMS(phoneNumber)
        .then((res) => {
          setId(res.data.id); // id 등록
          setOpen(true);
        })
        .catch((err) => alert(err.response.data && err.response.data.detail));
    }
  };

  /**
   * id 등록시
   * @description [2] 인증번호(6자리) 입력
   * @returns { 200 OK }
   */
  const authSMS = () => {
    if (code.length < 6) {
      alert("코드를 정확히 입력해주세요.");
      return;
    }
    postAuthSMS(id, code) // id(문자발송시 리턴값) + code(실제 문자내용)
      .then(() => {
        // 200 OK일때 username 출력
        findUsername(id)
          .then((res) => {
            console.log("이거 왜함?", res.data);
            closing();
          })
          .catch((err) => alert(err.response.data && err.response.data.detail));
      })
      .catch((err) => alert(err.response.data && err.response.data.detail));
  };

  /**
   * @description [3] 유저 토큰값
   * @returns { token }
   */
  const findPassword = () => {
    getToken(id, username)
      .then((res) => {
        setToken(res.data.token); // 비밀번호 변경 이동
        setFind(true); // 비밀번호 변경 이동
      })
      .catch((err) => alert(err.response.data));
  };

  /* 비밀번호 변경 */
  const changePassword = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (password.trim() === "") {
      alert("새 비밀번호를 입력해주세요");
      return;
    } else if (passwordConfirm.trim() === "") {
      alert("새 비밀번호 확인을 입력해주세요");
      return;
    } else if (!passwordCheck(password)) {
      alert("8~16자 영문, 숫자, 특수문자를 사용하세요.");
      return;
    } else if (password !== passwordConfirm) {
      alert("비밀번호가 일치하지 않습니다");
      return;
    }
    resetPassword(token, password, passwordConfirm)
      .then(() => {
        alert("비밀번호가 성공적으로 변경되었습니다.");
        setFind(false);
        setTenTime(false);
      })
      .catch((err) => alert(err.response.data && err.response.data.detail));
  };

  return (
    <ModalWrapper>
      {find && token ? (
        <ChangeModalForm>
          <section className="change-modal-content">
            <ChangeTitle>새로운 비밀번호를 입력해주세요.</ChangeTitle>
            <ChangeInformation>
              <input
                type="password"
                placeholder="새 비밀번호"
                className="password"
                name="password"
                onChange={onChange}
              />
              <input
                type="password"
                placeholder="새 비밀번호 확인"
                className="passwordConfirm"
                name="passwordConfirm"
                onChange={onChange}
              />
              <button type="button" onClick={changePassword}>
                확인
              </button>
            </ChangeInformation>
          </section>
        </ChangeModalForm>
      ) : (
        <ModalForm>
          <CloseButton>
            <Button onClick={() => setTenTime(!tenTime)}>
              <Close />
            </Button>
          </CloseButton>
          <section className="modal-content">
            <Title>
              가입 당시 등록했던 <br />
              아이디와 휴대폰 번호를 입력해 주세요.
            </Title>
            <Information>
              <input
                type="text"
                placeholder="아이디"
                className="username"
                name="username"
                onChange={onChange}
              />
              <input
                type="text"
                placeholder="휴대폰 번호"
                className="phoneNumber"
                name="phoneNumber"
                onChange={onChange}
              />
              {id ? (
                <>
                  <button type="button" className="authorization" disabled>
                    <span>인증</span>
                  </button>
                  <br />
                  <button type="button" className="find" onClick={findPassword}>
                    확인
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="authorization"
                    onClick={openPhoneModal}
                  >
                    <span>인증</span>
                  </button>
                  <br />
                  <button type="button" className="find" disabled>
                    확인
                  </button>
                </>
              )}
            </Information>
          </section>
          {open && (
            <ModalWrapper>
              <PopupForm>
                <CloseButton>
                  <Button onClick={closeModal}>
                    <Close />
                  </Button>
                </CloseButton>
                <section className="sub-modal-content">
                  <SubTitle>
                    휴대폰 인증 <br />
                    인증번호(6자리)를 입력해 주세요.
                  </SubTitle>
                  <SubInformation>
                    <input
                      className="authorization"
                      type="password"
                      maxLength={6}
                      placeholder="인증번호"
                      onChange={(e) => handleCode(e)}
                    />
                    <button type="submit" onClick={authSMS}>
                      인증
                    </button>
                  </SubInformation>
                </section>
              </PopupForm>
            </ModalWrapper>
          )}
        </ModalForm>
      )}
    </ModalWrapper>
  );
}

export default AuthThreshold;

export const ModalForm = styled.article`
  position: relative;
  padding: 1.5rem 2rem;
  width: 35rem;
  height: 20rem;

  background-color: #ffffff;
  letter-spacing: 1px;
  border-radius: 20px;
  box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.15);

  section.modal-content {
    margin: 1.5rem auto;
    width: 21.5rem;
    height: auto;
  }
`;
export const PopupForm = styled.article`
  position: relative;
  padding: 1.5rem 2rem;
  width: 25rem;
  height: 13rem;

  background-color: #ffffff;
  letter-spacing: 1px;
  border-radius: 20px;
  box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.15);

  section.sub-modal-content {
    margin: 1.5rem auto;
    width: 17.5rem;
    height: auto;
  }
`;
export const ChangeModalForm = styled.article`
  position: relative;
  padding: 1.5rem 2rem;
  width: 26rem;
  height: 19rem;

  background-color: #ffffff;
  letter-spacing: 1px;
  border-radius: 20px;
  box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.15);

  section.change-modal-content {
    margin: 2rem auto;
    width: 21.5rem;
    height: auto;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 1rem;
  right: 0.5rem;
`;

export const Title = styled.header`
  line-height: 1.7rem;
  font-size: 1.3rem;
`;
export const SubTitle = styled.header`
  line-height: 1.6rem;
  font-size: 1.2rem;
`;
export const ChangeTitle = styled.header`
  width: 100%;
  text-align: center;
  line-height: 1.6rem;
  font-size: 1.2rem;
`;

export const Information = styled.main`
  margin-top: 1rem;

  input {
    margin-bottom: 0.5rem;
    width: 16rem;
    height: 2.5rem;
    font-size: 1rem;
  }

  button {
    height: 2.5rem;
    line-height: 2.4rem;
    font-size: 0.9rem;
    font-weight: 500;
    color: #fff;
    background-color: #3f51b5;

    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  button.authorization {
    margin-left: 0.5rem;
    width: 4rem;
    &:disabled {
      background-color: lightgray;
      cursor: default;
    }
  }

  button.find {
    margin-top: 0.5rem;
    width: 20.5rem;
    &:disabled {
      background-color: lightgray;
      cursor: default;
    }
  }
`;
export const SubInformation = styled.main`
  margin-top: 1rem;

  input {
    width: 13.5rem;
    height: 2.5rem;
    font-size: 1rem;
  }

  button {
    margin-left: 0.5rem;

    width: 3rem;
    height: 2.5rem;
    line-height: 2.4rem;
    font-size: 0.9rem;
    font-weight: 500;
    color: #fff;
    background-color: #3f51b5;

    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
`;
export const ChangeInformation = styled.main`
  margin: 1rem auto;
  width: 20rem;

  input {
    margin-bottom: 0.5rem;
    padding-left: 0.3rem;

    width: 100%;
    height: 2.5rem;
    font-size: 1rem;
  }

  button {
    margin-top: 0.5rem;

    width: 100%;
    height: 2.5rem;
    line-height: 2.4rem;

    font-size: 0.9rem;
    font-weight: 500;
    color: #fff;
    background-color: #3f51b5;

    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
`;
