import { localAddress, localKeyword } from "../../lib/api/base";
import { sleep } from "../../lib/core";
import {
  PromiseFulfilledResult,
  SearchType,
  ExcelDataType,
} from "./LargeAddressType";

const DEFAULT_EXCEL_DATA = {
  address: "",
  address_name: "",
  road_address_name: "",
};

export const LargeAddressCalculate = async (
  _: string, // channel props
  removedHeaderData: string[][]
) => {
  let jibunOrRoadAddress: ExcelDataType[] = [];
  try {
    for (let arr of removedHeaderData) {
      const response = (await Promise.allSettled([
        localAddress(arr[1], 1),
        localKeyword(arr[1], 1),
      ])) as PromiseFulfilledResult<SearchType[]>[];

      // localAddress
      const { status: addressStatus, value: addressValue } = response[0];
      // localKeyword
      const { status: keywordStatus, value: keywordValue } = response[1];

      if (addressStatus === "fulfilled" && addressValue.length > 0) {
        // localAddress
        const { address, address_name, road_address_name } = addressValue[0];
        jibunOrRoadAddress = [
          ...jibunOrRoadAddress,
          { address, address_name, road_address_name },
        ];
      } else if (keywordStatus === "fulfilled" && keywordValue.length > 0) {
        // localKeyword
        const { address, address_name, road_address_name } = keywordValue[0];
        jibunOrRoadAddress = [
          ...jibunOrRoadAddress,
          { address, address_name, road_address_name },
        ];
      } else {
        jibunOrRoadAddress = [...jibunOrRoadAddress, DEFAULT_EXCEL_DATA];
      }
      await sleep(1000);
    }
    return registExcel(jibunOrRoadAddress, removedHeaderData);
  } catch (error) {
    throw new Error("주소 검색에 실패했습니다. 개발팀에 문의해주세요.");
  }
};

const registExcel = (
  jibunOrRoadAddress: ExcelDataType[],
  removedHeaderData: string[][]
) => {
  for (let index in removedHeaderData) {
    removedHeaderData[index][0] = (Number(index) + 1).toString(); // NO.
    removedHeaderData[index][2] =
      jibunOrRoadAddress[index].address ||
      jibunOrRoadAddress[index].address_name ||
      "";
    removedHeaderData[index][3] =
      jibunOrRoadAddress[index].road_address_name || "";
  }
  return removedHeaderData;
};
