import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Snackbar } from "@material-ui/core";
import useSnackbar from "../../hooks/useSnackbar";
import AddLoadingChargeSelector from "../../components/corporation/AddLoadingChargeSelector";
import ExtraLoadingChargeList from "../../components/corporation/ExtraLoadingChargeList";
import NoExtraChargeList from "../../components/corporation/NoExtraChargeList";
import Notice from "../../components/common/Notice";
import { destructResponse } from "../../lib/hasura/common";
import {
  getCorpLoadingDamasLaboCharge,
  createCorpLoadingDamasLaboCharge,
  updateCorpLoadingDamasLaboCharge,
  deleteCorpLoadingDamLaboCharge,
} from "../../lib/hasura/extraCharge";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import {
  ADD_LOADING_CHARGE,
  DamasLaboLoadingSelector,
  LoadingChargeType,
} from "./extraTypes";
import { validateExtraLoadingCharge } from "./utils";

interface LoadingChargeContainerProps {
  corpId: string;
  corpName: string;
}

function CorporationLoadingChargeContainer({
  corpId,
  corpName,
}: LoadingChargeContainerProps) {
  const dispatch = useDispatch();
  const [addLoadingCharge, setAddLoadingCharge] = useState(ADD_LOADING_CHARGE); // 등록할 상하차요금
  const [loadingData, setLoadingData] = useState<LoadingChargeType[]>([]); // 상하차요금 데이터 (다마스/라보)
  const [vehicleType, setVehicleType] = useState<"DAMAS" | "LABO">("DAMAS"); // 차량타입

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  // mounted
  useEffect(() => {
    fetchLoadingData(vehicleType);
  }, [corpId, vehicleType]);

  const fetchLoadingData = async (type: string) => {
    dispatch(startGlobalLoading());
    try {
      const response = await destructResponse<LoadingChargeType[]>(
        "corporations_corporationloadingcharge",
        () => getCorpLoadingDamasLaboCharge(corpId, type)
      );
      setLoadingData(response);
    } catch (err) {
      console.log(err);
      openSnackbar("상하차요금을 불러오는데 실패하였습니다.", true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  // 전체 상하차요금 생성
  const onAllRegist = async () => {
    const confirmMsg = `${corpName}의 "다마스/라보" 상하차요금을 생성하시겠습니까?`;
    if (!confirm(confirmMsg)) return;

    dispatch(startGlobalLoading());
    try {
      await createCorpLoadingDamasLaboCharge(corpId);
      await fetchLoadingData(vehicleType);
    } catch (err) {
      const failMsg = (err as Error).message || "요금 등록에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const onChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { name, value } = e.target;
    setAddLoadingCharge({ ...addLoadingCharge, [name as string]: value });
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(startGlobalLoading());
    try {
      const invalidMessage = validateExtraLoadingCharge(addLoadingCharge);
      if (invalidMessage) throw new Error(invalidMessage);

      const confirmMsg = `해당 범위의 "다마스/라보" 상하차요금을 등록하시겠습니까?`;
      if (!confirm(confirmMsg)) return;

      const { loading, unloading } = addLoadingCharge;
      if (loading === unloading)
        await updateCorpLoadingDamasLaboCharge(corpId, addLoadingCharge);
      else {
        // loading unloading의 반대 데이터를 한번 더 등록
        await updateCorpLoadingDamasLaboCharge(corpId, addLoadingCharge);
        await updateCorpLoadingDamasLaboCharge(corpId, {
          ...addLoadingCharge,
          unloading: loading,
          loading: unloading,
        });
      }

      fetchLoadingData(vehicleType);
      openSnackbar("요금이 등록되었습니다.", false);
    } catch (err) {
      const failMsg = (err as Error).message || "요금 등록에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const onAllDelete = async () => {
    const deleteMsg = `"다마스/라보" 상하차요금을 전체 삭제하시곘습니까?\n전체 삭제시 "기존 상하차요금"으로 자동 적용됩니다.`;
    if (!confirm(deleteMsg)) return;

    dispatch(startGlobalLoading());
    try {
      await deleteCorpLoadingDamLaboCharge(corpId);
      fetchLoadingData(vehicleType);
      openSnackbar("삭제가 완료되었습니다.", false);
    } catch (err) {
      const failMsg = (err as Error).message || "요금 삭제에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return;
    closeSnackbar();
  };

  return (
    <>
      <LoadingWrapper>
        <LoadingHeader>
          <h1 className="title">다마스/라보 상하차요금 DB화 ({corpName})</h1>
          <div className="description">
            *상하차요금은 부분삭제가 불가하며, 전체삭제시 "기존 상하차요금"으로
            자동 적용됩니다.
          </div>
          <div className="description">
            *상차와 하차의 방법이 다른경우, 반대의 상하차 방법도 함께
            등록됩니다.
          </div>
        </LoadingHeader>
        {loadingData.length > 0 ? (
          <LoadingBody>
            <AddLoadingChargeSelector
              addCharge={addLoadingCharge}
              selector={DamasLaboLoadingSelector}
              onChange={onChange}
              onSubmit={onSubmit}
              onAllDelete={onAllDelete}
              isTruckOption={false}
            />
            <ExtraLoadingChargeList
              vehicleType={vehicleType}
              setVehicleType={setVehicleType}
              loadingData={loadingData}
            />
          </LoadingBody>
        ) : (
          <NoExtraChargeList type={"상하차"} onAllRegist={onAllRegist} />
        )}
      </LoadingWrapper>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}

export default CorporationLoadingChargeContainer;

const LoadingWrapper = styled.section`
  margin-top: 20px;
  text-align: center;
`;

const LoadingHeader = styled.header`
  padding-bottom: 12px;

  .title {
    margin: 10px;
    font-size: 1.7rem;
  }
  .description {
    margin-bottom: 3px;
    font-size: 18px;
    font-weight: 800;
    color: #9e9e9e;
  }
`;

const LoadingBody = styled.section`
  display: flex;
  flex-direction: column;
`;
