import { formatDate } from "../../lib/datetime";

export const headRows = [
  {
    id: "seq",
    numeric: true,
    disablePadding: false,
    label: "행 번호",
  },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "등록날짜",
    format: ({ created }: LogElementType) => formatDate(created),
  },
  {
    id: "content_type",
    numeric: false,
    disablePadding: false,
    label: "로그 타입",
  },
  {
    id: "content",
    numeric: false,
    disablePadding: false,
    label: "로그 내용",
    maxWidth: 40,
  },
  {
    id: "menu_name",
    numeric: false,
    disablePadding: false,
    label: "사용한 메뉴",
    format: ({ menu_name: menuName }: LogElementType) =>
      (menuName !== "null" && menuName !== "undefined" && menuName) || "",
  },
  {
    id: "userUsername",
    numeric: false,
    disablePadding: false,
    label: "아이디",
  },
  {
    id: "userFullname",
    numeric: false,
    disablePadding: false,
    label: "이름",
  },
];

export interface LogElementType {
  seq: number;
  created: string;
  content: string;
  content_type: string;
  id: number;
  menu_name: string;
  user_id: string;
  userUsername: string;
  userFullname: string;
  users_user: {
    username: string;
    fullname: string;
  };
}
