export type SearchDataType = {
  id: string;
  letter: string;
  name: string;
};

export type CorporationsListType = {
  id: string;
  name: string;
};

export type ChargeLocationType = {
  charge_locationl1: {
    name: string;
  };
  charge_locationl2: {
    name: string;
  };
  name: string;
};

export type AlgoquickChargeType = {
  [x: string]: number;
  damas: number;
  labo: number;
  motorcycle: number;
  truck_1_4t: number;
  truck_1t: number;
  truck_2_5t: number;
  truck_3_5t: number;
  truck_5t: number;
  truck_8t: number;
  truck_11t: number;
  truck_14t: number;
  truck_15t: number;
  truck_18t: number;
  truck_25t: number;
};

export type CorpChargeDataType = {
  [x: string]: any;
  AlgoQuickCharge_id: string;
  baseCharge: number;
  charge: number;
  chargeLocationl3ByDepartureId: ChargeLocationType;
  charge_algoquickcharge: AlgoquickChargeType;
  charge_locationl3: ChargeLocationType;
  corporation_id: string;
  created: string;
  departure_id: string;
  destination_id: string;
  id: number;
  modified: string;
  vehicle_type: string;
};

export type CorpChargeByLocVeh = {
  id: number;
  chargeId: string;
  departure: ChargeLocationType;
  destination: ChargeLocationType;
  baseCharge: number;
  customCharge: number;
  vehicleType: string;
};

export type AddressType = {
  address: string;
  address_detail: string | null;
  address_name: string | null;
  b_code: string | null;
  id: string | null;
  latitude: string;
  longitude: string;
  place_name: string | null;
  road_address_name: string | null;
};

export type ChargeDataType = {
  AlgoQuickCharge_id: string;
  charge: number;
  corporation_id: string;
  departure_id: string;
  destination_id: string;
  vehicle_type: string;
};

export type UploadDataType = {
  departure_id: string;
  destination_id: string;
  vehicle_type: string;
  charge: number;
};

type NormalVehicleEnglishType = {
  [index: string]: string;
  오토바이: string;
  다마스: string;
  라보: string;
};
export const normalVehicleToEnglish: NormalVehicleEnglishType = {
  ["오토바이"]: "motorcycle",
  ["다마스"]: "damas",
  ["라보"]: "labo",
};

// 데이터 형식이 다르다. hasura는 "_cargo", REST API는 "/CARGO"
type TruckOptionsEnglishType = {
  [key: string]: string;
  ["카고"]: string;
  ["호루"]: string;
  ["탑차"]: string;
  ["윙바디"]: string;
  ["냉장탑차"]: string;
  ["냉장윙바디"]: string;
  ["냉동탑차"]: string;
  ["냉동윙바디"]: string;
};
export const hasuraTruckOptionsToEnglish: TruckOptionsEnglishType = {
  ["카고"]: "_cargo",
  ["호루"]: "_canvas",
  ["탑차"]: "_box",
  ["윙바디"]: "_wing",
  ["냉장탑차"]: "_chiller_box",
  ["냉장윙바디"]: "_chiller_wing",
  ["냉동탑차"]: "_freezer_box",
  ["냉동윙바디"]: "_freezer_wing",
};

type TruckOptionsKoreanType = {
  [key: string]: string;
  _cargo: string;
  _canvas: string;
  _box: string;
  _wing: string;
  _chiller_box: string;
  _chiller_wing: string;
  _freezer_box: string;
  _freezer_wing: string;
};
export const hasuraTruckOptionsToKorean: TruckOptionsKoreanType = {
  _cargo: "카고",
  _canvas: "호루",
  _box: "탑차",
  _wing: "윙바디",
  _chiller_box: "냉장탑차",
  _chiller_wing: "냉장윙바디",
  _freezer_box: "냉동탑차",
  _freezer_wing: "냉동윙바디",
};
