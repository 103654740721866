export type CorporateUser = {
  id: string;
  username: string;
};

export type ExcelColumn = {
  key: string;
  label: string;
  default: boolean;
  hide?: boolean;
};
export type ExcelColumnOption = ExcelColumn & {
  value: boolean;
};

export type ChangeColumnOptionHandler = (
  e: React.ChangeEvent<HTMLInputElement>
) => void;

export type CorpPropsKey = keyof CorpProps;
export type CorpProps = {
  id?: string;
  logo?: string | null;
  name: string;
  channel: string;
  phone: string;
  address: string;
  registration_number: string;
  contracted: string;
  limit_budget: number;
  fixed_date: number;
  discount_rate: number;
  administrator_id: string | null;
  budget_enable: boolean;
  restrict_by_budget: boolean;
  order_approval_amt: number;
  order_approval_enable: boolean;
  order_approval_user_select_enable: boolean;
  order_approval_time_enable: boolean;
  order_approval_time_holiday: boolean;
  order_approval_time_weekend: boolean;
  order_approval_time_start: string;
  order_approval_time_end: string;
  order_client_fix_enable: boolean;
  billing_department_enable: boolean;
  costcenter_change_memo_enable: boolean;
  corporate_account_enable: boolean;
  corporate_order_memo_enable: boolean;
  corporate_card_memo_enable: boolean;
  express_order_memo_enable: boolean;
  corporate_credit_enable: boolean;
  corporate_department_enable: boolean;
  corporate_department_select_enable: boolean;
  corporate_department_select_type: string | null;
  corporate_email_address: string | null;
  corporate_email_address_list: string[];
  corporate_credit: number;
};

export type BulkType = {
  big_box: number;
  big_sack: number;
  big_shopping_bag: number;
  corporation_id: string;
  corporations_corporation?: { name: string };
  medium_box: number;
  oversize_big_box: number;
  shopping_bag: number;
  small_box: number;
  small_sack: number;
};

export type CorporateProject = {
  id: number;
  name: string;
  limit_budget: number;
  corporations_corporation: { name: string };
};

// CorporationAddContainer
export const DEFAULT_CORP_ADD = {
  name: "",
  channel: "algoquick",
  phone: "",
  address: "",
  registration_number: "",
  contracted: new Date().toISOString().slice(0, 10),
  limit_budget: 0,
  fixed_date: 1,
  discount_rate: 0,
  administrator_id: null,
  budget_enable: false,
  restrict_by_budget: false,
  order_approval_amt: 0,
  order_approval_enable: false,
  order_approval_user_select_enable: false,
  order_approval_time_enable: false,
  order_approval_time_holiday: false,
  order_approval_time_weekend: false,
  order_approval_time_start: "00:00",
  order_approval_time_end: "24:00",
  order_client_fix_enable: false,
  billing_department_enable: false,
  costcenter_change_memo_enable: false,
  corporate_account_enable: true,
  corporate_order_memo_enable: false,
  corporate_card_memo_enable: false,
  express_order_memo_enable: false,
  corporate_credit_enable: false,
  corporate_department_enable: true,
  corporate_department_select_enable: false,
  corporate_department_select_type: "1", // 생성시 선택형 기본값
  corporate_email_address: "",
  corporate_email_address_list: [],
  corporate_credit: 0,
};

// CorporationDetailContainer
export const DEFAULT_CORP_DETAIL = {
  id: "",
  name: "",
  channel: "algoquick",
  phone: "",
  address: "",
  registration_number: "",
  contracted: "",
  limit_budget: 0,
  fixed_date: 1,
  discount_rate: 0,
  administrator_id: null,
  budget_enable: false,
  restrict_by_budget: false,
  order_approval_amt: 0,
  order_approval_enable: false,
  order_approval_user_select_enable: true,
  order_approval_time_enable: false,
  order_approval_time_holiday: false,
  order_approval_time_weekend: false,
  order_approval_time_start: "00:00",
  order_approval_time_end: "24:00",
  order_client_fix_enable: false,
  billing_department_enable: false,
  costcenter_change_memo_enable: false,
  corporate_account_enable: false,
  corporate_order_memo_enable: false,
  corporate_card_memo_enable: false,
  express_order_memo_enable: false,
  corporate_credit_enable: false,
  corporate_department_enable: true,
  corporate_department_select_enable: false,
  corporate_department_select_type: null, // 기존 법인은 처음에 null
  corporate_email_address: null,
  corporate_email_address_list: [],
  corporate_credit: 0,
};

// CorporationBulkContainer
export const DEFAULT_BULK_DATA = {
  big_box: 3,
  big_sack: 5,
  big_shopping_bag: 2,
  corporation_id: "",
  corporations_corporation: { name: "" },
  medium_box: 2.5,
  oversize_big_box: 5,
  shopping_bag: 1.5,
  small_box: 1.5,
  small_sack: 2,
};

export const DEFAULT_PROJECT = {
  id: 0,
  name: "",
  corporations_corporation: { name: "" },
  limit_budget: 0,
};

export const headRows = [
  {
    id: "corporation",
    numeric: false,
    disablePadding: false,
    label: "법인명",
  },
  { id: "name", numeric: false, disablePadding: false, label: "프로젝트명" },
  {
    id: "limit_budget",
    numeric: false,
    disablePadding: false,
    label: "예산한도",
  },
];

export const radioInputs = [
  {
    value: "0",
    label: "고정형",
  },
  {
    value: "1",
    label: "선택형",
  },
  {
    value: "2",
    label: "선택입력형",
  },
];

// 법인관리 엑셀 업로드 헤더
export const headRowsDepart = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "부서명(필수*)",
  },
  {
    id: "limit_budget",
    numeric: false,
    disablePadding: false,
    label: "예산한도(필수*)",
  },
];

export const headRowsUser = [
  {
    id: "username",
    numeric: false,
    disablePadding: false,
    label: "아이디(필수*)",
  },
  {
    id: "password",
    numeric: false,
    disablePadding: false,
    label: "비밀번호(필수*)",
  },
  {
    id: "fullname",
    numeric: false,
    disablePadding: false,
    label: "이름(필수*)",
  },
  {
    id: "department",
    numeric: false,
    disablePadding: false,
    label: "부서",
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: "연락처(필수*)",
  },
  { id: "email", numeric: false, disablePadding: false, label: "이메일" },
];

export const headRowsProject = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "프로젝트명(필수*)",
  },
  {
    id: "limit_budget",
    numeric: false,
    disablePadding: false,
    label: "예산한도(필수*)",
  },
];

export const validateProject = (row: CorporateProject) => {
  let errorMessage = "";
  if (row.name.trim() === "") errorMessage = "프로젝트명을 입력해주세요.";
  else if (row.limit_budget.toString() === "")
    errorMessage = "예산한도를 입력해주세요.";
  return errorMessage;
};
