import React, { useMemo } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {
  TextField,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
} from "@material-ui/core";
import { UserType } from "../../containers/user/types";
import { CorpListType, DepartListType } from "../../containers/user/types";
import { Autocomplete } from "@material-ui/lab";
import { checkField } from "../../lib/validate";
import { ascendingFunc } from "../../lib/core";
import Detail from "../common/Detail";
import {
  FlexBox,
  FlexCenterSpaceBetween,
  FlexColumnCenter,
  RowDivider,
  CustomInputLabel,
} from "../common/Base";

interface UserDetailProps {
  row: UserType;
  corp: CorpListType[];
  depart: DepartListType[];
  onChange: (e: any) => void;
  onSubmit: (e: React.FormEvent) => void;
  handleUserAdmin: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePlatform: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

type DefaultValue = {
  id: string;
  name: string;
};

const PLATFORMS = [
  "ALGOQUICK",
  "BIZPLAY",
  "CAKE",
  "DAOUOFFICE",
  "INNOCEAN",
  "YEDAHAM",
];

function UserDetail({
  row,
  corp,
  depart,
  onChange,
  onSubmit,
  handleUserAdmin,
  handlePlatform,
}: UserDetailProps) {
  const history = useHistory();

  // 법인명 정렬 + 오름차순(ascendingFunc)
  const sort_corp = useMemo(
    () =>
      corp.map((el: CorpListType) => {
        const letter = el.name.toUpperCase().replace(/ /g, "");
        return {
          letter,
          ...el,
        };
      }),
    [corp]
  );
  // 부서명 정렬
  const sort_depart = useMemo(
    () =>
      depart.map((el: DepartListType) => {
        const letter = el.name.toUpperCase().replace(/ /g, "");
        return {
          letter,
          ...el,
        };
      }),
    [depart]
  );

  const inputValue = useMemo(() => {
    // 법인생성 페이지는 value값 미사용
    if (!row?.id) return undefined;

    // 법인수정 페이지
    if (row.corporation.trim())
      return {
        id: row.corporation,
        name: row.corporation_name,
      } as DefaultValue;
    else {
      return null; // 법인명 backspace
    }
  }, [row.id, row.corporation]);

  /**
   * @description !row.id -> 회원생성시
   * @description row.id -> 회원수정시
   */
  return (
    <FlexColumnCenter>
      <Detail>
        <TextField
          label="고객코드"
          id="isd_c_code"
          name="isd_c_code"
          value={checkField(row.isd_c_code)}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={onChange}
          disabled={!row.id}
        />
        <TextField
          label="아이디*"
          id="username"
          name="username"
          value={checkField(row.username)}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={onChange}
          disabled={!!row.id}
        />
        {!row.id && (
          <>
            <TextField
              label="비밀번호*"
              type="password"
              id="password"
              name="password"
              value={checkField(row.password)}
              variant="outlined"
              margin="normal"
              fullWidth
              onChange={onChange}
            />
            <TextField
              label="비밀번호 확인*"
              type="password"
              id="passwordCheck"
              name="passwordCheck"
              value={checkField(row.passwordCheck)}
              variant="outlined"
              margin="normal"
              fullWidth
              onChange={onChange}
            />
          </>
        )}
        <TextField
          label="이름*"
          id="fullname"
          name="fullname"
          value={checkField(row.fullname)}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={onChange}
        />
        <TextField
          label="연락처*"
          type="number"
          id="phone"
          name="phone"
          value={checkField(row.phone)}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={onChange}
          disabled={!!row.id}
        />
        <TextField
          label="이메일"
          type="email"
          id="email"
          name="email"
          value={checkField(row.email)}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={onChange}
        />
        <RowDivider />
        {sort_corp && (
          <>
            <FlexBox>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleUserAdmin}
                    name="admin"
                    color="primary"
                  />
                }
                label="어드민 계정으로 사용"
                labelPlacement="end"
              />
              <InfoMessage>
                (*알고랩 등록시 법인+부서 모두 등록 | 타법인 등록시 법인만 등록
                가능)
              </InfoMessage>
            </FlexBox>
            <Autocomplete
              id="corporation-combo-box"
              fullWidth
              options={sort_corp.sort(ascendingFunc)}
              value={inputValue}
              onChange={(event, newValue) => {
                const value = newValue || "";
                onChange({ target: { name: "corporation", value } });
              }}
              getOptionLabel={({ name }) => name}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="corporation"
                  label="법인명"
                  variant="outlined"
                />
              )}
            />
            <RowDivider />
            <FormControl variant="outlined" fullWidth>
              <CustomInputLabel>부서명</CustomInputLabel>
              <Select
                value={row.department}
                onChange={onChange}
                input={<OutlinedInput name="department" id="department" />}
              >
                <MenuItem value=" ">
                  <em>None</em>
                </MenuItem>
                {sort_depart
                  .sort(ascendingFunc)
                  .map((option: DepartListType) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {row.platform && row.platform.includes("CAKE") && (
              <TextField
                label="고바이크 회원전용 크레딧"
                type="number"
                id="user_credit"
                name="user_credit"
                value={row.user_credit}
                inputProps={{ min: "0", step: "1000" }}
                variant="filled"
                margin="normal"
                fullWidth
                onChange={onChange}
              />
            )}
          </>
        )}
        <PlatformBox>
          <FormLabel component="legend">가입 경로</FormLabel>
          {row.id && (
            <FlexBox>
              {PLATFORMS.map((platform) => (
                <FormControlLabel
                  key={platform}
                  control={
                    <Checkbox
                      checked={row.platform.includes(platform)}
                      onChange={handlePlatform}
                      name={platform}
                      color="primary"
                    />
                  }
                  label={platform}
                  labelPlacement="end"
                />
              ))}
            </FlexBox>
          )}
        </PlatformBox>
        <FlexCenterSpaceBetween>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => history.goBack()}
          >
            취소
          </Button>
          <Button variant="outlined" color="primary" onClick={onSubmit}>
            저장
          </Button>
        </FlexCenterSpaceBetween>
      </Detail>
    </FlexColumnCenter>
  );
}

export default UserDetail;

const InfoMessage = styled.span`
  display: flex;
  align-items: center;
  font-weight: 700;
  color: #9e9e9e;
`;

const PlatformBox = styled.section`
  margin: 2rem 0;
`;
