import React from "react";
import styled from "styled-components";
import algoLogo from "../../assets/Order/new-footer-black-logo.svg";
import algoStamp from "../../assets/Order/algolab-stamp.png";

function UseStatementFooter() {
  return (
    <Footer>
      <div className="footer-wrapper">
        <img className="footer-logo" src={algoLogo} alt="알고랩" />
        <section className="footer-info">
          <table>
            <thead>
              <tr>
                <th>상호</th>
                <th>업태/종목</th>
                <th>사업자번호</th>
                <th>소재지</th>
                <th>대표자</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>주식회사 알고랩</td>
                <td>서비스/퀵서비스</td>
                <td>718-81-00565</td>
                <td className="address">
                  <div>서울 강남구 봉은사로 331,</div>
                  <div>SH 타워 9 층</div>
                </td>
                <td>
                  <span>백승환&emsp;&emsp;(인)</span>
                  <img className="stamp" src={algoStamp} alt="알고랩인의" />
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </Footer>
  );
}
export default UseStatementFooter;

const Footer = styled.footer`
  width: 100%;
  height: 14rem;
  background-color: #e5e5e5;

  .footer-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0 2rem;
    img.footer-logo {
      margin: 1rem 0;
      align-self: self-end;
      width: 11rem;
    }

    section.footer-info {
      text-align: center;
      table {
        width: 100%;
        thead {
          height: 1.5rem;
          color: #fff;
          background-color: #a9a9a9;
          tr {
            th:nth-child(1),
            th:nth-child(2),
            th:nth-child(3) {
              width: 17%;
            }
            th:nth-child(4) {
              width: 27%;
            }
            th:nth-child(5) {
              width: 22%;
            }
          }
        }
        tbody {
          height: 3.5rem;
          color: #797979;
          background-color: #fff;
          tr {
            td:nth-child(1),
            td:nth-child(2),
            td:nth-child(3) {
              width: 17%;
              border-right: 1px solid #e5e5e5;
            }
            td:nth-child(4) {
              width: 27%;
              border-right: 1px solid #e5e5e5;
            }
            td:nth-child(5) {
              position: relative;
              width: 22%;
              img.stamp {
                position: absolute;
                top: 9px;
                right: 22px;
                width: 40px;
                height: 40px;
              }
            }
          }
        }
      }
    }
  }
`;
